import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TransactionStateType } from "../../types";
import {
  getAllAssets,
  getAllBankFormats,
  getAllTransactions,
  getAsset,
} from "./transactionsAsyncThunk";

const initialState: TransactionStateType = {
  transactions: {
    loading: false,
    error: "",
    data: [],
    pagination: {
      currentPage: 1,
      totalItems: 1,
      limit: 20,
    },
  },
  assets: {
    loading: false,
    error: "",
    data: [],
    pagination: {
      currentPage: 1,
      totalItems: 1,
      limit: 20,
    },
  },
  bank_formats: {
    loading: false,
    error: "",
    data: [],
    pagination: {
      currentPage: 1,
      totalItems: 1,
      limit: 20,
    },
  },
  single_asset: {
    data: null,
    error: "",
    loading: false,
  },
  searchTerm: "",
};

const transactions = createSlice({
  name: "transactions",
  reducers: {
    setCurrentPage(
      state,
      action: PayloadAction<{
        pageNumber: number;
        desc: "bank_formats" | "assets" | "transactions";
      }>
    ) {
      state[action.payload.desc].pagination!.currentPage =
        action.payload.pageNumber;
    },

    setSearchTerm(state, action: PayloadAction<{ searchTerm: string }>) {
      state.searchTerm = action.payload.searchTerm;
    },
  },
  initialState,
  extraReducers: (builders) => {
    builders.addCase(getAllTransactions.pending, (state) => {
      state.transactions.loading = true;
    });

    builders.addCase(getAsset.pending, (state) => {
      state.single_asset.error = "";
      state.single_asset.loading = true;
    });

    builders.addCase(getAsset.fulfilled, (state, action) => {
      state.single_asset.error = "";
      state.single_asset.data = action.payload;
      state.single_asset.loading = false;
    });

    builders.addCase(getAsset.rejected, (state, action) => {
      if (typeof action.payload === "string")
        state.single_asset.error = action.payload;

      state.single_asset.loading = false;
    });

    builders.addCase(getAllTransactions.fulfilled, (state, action) => {
      state.transactions.data = action.payload.data;
      state.transactions.pagination.totalItems = action.payload?.results || 1;
      state.transactions.loading = false;
      state.transactions.error = "";
    });
    builders.addCase(getAllTransactions.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.transactions.error = action.payload;
      }
      state.transactions.loading = false;
    });

    builders.addCase(getAllAssets.pending, (state) => {
      state.assets.loading = true;
    });

    builders.addCase(getAllAssets.fulfilled, (state, action) => {
      state.assets.data = action.payload.data;
      state.assets.pagination.totalItems = action.payload?.results || 1;
      state.assets.loading = false;
      state.assets.error = "";
    });

    builders.addCase(getAllAssets.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.assets.error = action.payload;
      }
      state.assets.loading = false;
    });

    builders.addCase(getAllBankFormats.pending, (state) => {
      state.bank_formats.loading = true;
    });

    builders.addCase(getAllBankFormats.fulfilled, (state, action) => {
      state.bank_formats.data = action.payload.data;
      state.bank_formats.pagination.totalItems = action.payload?.results || 1;
      state.bank_formats.loading = false;
      state.bank_formats.error = "";
    });

    builders.addCase(getAllBankFormats.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.bank_formats.error = action.payload;
      }
      state.bank_formats.loading = false;
    });
  },
});

export const { setCurrentPage, setSearchTerm } = transactions.actions;

export default transactions.reducer;
