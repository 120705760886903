import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CurrentDashboardType, DashboardType } from "../../types";
import {
  getUserDashboardPreference,
  storeUserDashboardPreference,
} from "common/service/storage";

const initialState: DashboardType = {
  modal: false,
  modalType: "",
  modalMessage: "",
  currentDashboard: getUserDashboardPreference(),
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    openmodal: (state, action) => {
      state.modal = true;
      state.modalType = action.payload;
    },
    messagemodal: (state, action) => {
      state.modal = true;
      state.modalType = action.payload.type;
      state.modalMessage = action.payload.message;
    },
    closemodal: (state) => {
      state.modal = initialState.modal;
      state.modalType = initialState.modalType;
      state.modalMessage = initialState.modalMessage;
    },
    changeDashboard: (
      state,
      action: PayloadAction<{ dashboard: CurrentDashboardType }>
    ) => {
      storeUserDashboardPreference(action.payload.dashboard);
      state.currentDashboard = action.payload.dashboard;
    },
  },
});

export const { changeDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
