import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "common/components/CustomToast";
import service from "common/service/protected";
import { RequestBodyType } from "types";

export const getAllSavingsPlan = createAsyncThunk<any, RequestBodyType>(
  "savings/getAllSavingsPlan",
  async (data, { rejectWithValue }) => {
    return service
      .getAllSavingsPlan(data)
      .then((res) => res)
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const getSavingsTransactions = createAsyncThunk<any, RequestBodyType>(
  "savings/getSavingsTransactions",
  async (data, { rejectWithValue }) => {
    return service
      .getAllTransactions(data)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);
