import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  storeRefreshToken,
  storeUserToken,
} from "../../common/service/storage";
import {
  getUser,
  loginAdmin,
  twoFactorAuth,
  resetPassword,
  updateUserSettings,
} from "./authAsyncThunk";
import { AuthType } from "../../types";

const initialState: AuthType = {
  loading: false,
  error: null,
  auth: {
    stage: "forgotpassword",
    email: "",
    code: "",
    password: "",
    confirm_password: "",
  },
  admin: {
    access_token: null,
    refresh_token: null,
    user_permissions: {
      login: false,
      view_transactions: false,
      deactivate_user: false,
      create_admin: false,
      view_transaction_value: false,
      approve_vendor: false,
      terminate_vendor_contracts: false,
    },
    role: "",
    account_verified: false,
    is_active: false,
    is_verified: false,
    _id: "",
    fullname: "",
    country: "",
    phone: "",
    ntf_token: "",
    email: "",
    created_at: "",
    created_by: "",
    verification_required: false,
    __v: 0,
    settings: {
      two_fa: false,
      google_two_fa: false,
      email_notification: false,
      in_app_notification: false,
    },
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateStage: (state, action) => {
      state.auth.stage = action.payload;
    },
    updateField: (state, action) => {
      state.auth[action.payload.name] = action.payload.value;
    },
    resetFields: (state) => {
      Object.keys(state.auth).forEach((key) => {
        state.auth[key] = initialState.auth[key];
      });
    },
    logoutAdmin: (state) => {
      state.admin.account_verified = initialState.admin.account_verified;
    },
    resetAuthError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginAdmin.fulfilled, (state, action) => {
        if (action.payload.access_token) {
          Object.keys(action.payload).forEach((key) => {
            state.admin[key] = action.payload[key];
          });
          storeUserToken(state.admin.access_token);
          storeRefreshToken(state.admin.refresh_token);
        }
      })
      .addCase(twoFactorAuth.fulfilled, (state) => {
        state.admin.account_verified = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        Object.keys(action.payload).forEach((key) => {
          state.admin[key] = action.payload[key];
        });
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        if (action.payload && action.payload?.settings) {
          state.admin.settings = action.payload.settings;
        }
      })
      .addMatcher(
        isAnyOf(
          loginAdmin.fulfilled,
          twoFactorAuth.fulfilled,
          resetPassword.fulfilled,
          updateUserSettings.fulfilled,
          getUser.fulfilled
        ),
        (state) => {
          state.loading = false;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          loginAdmin.rejected,
          twoFactorAuth.rejected,
          resetPassword.rejected,
          updateUserSettings.rejected,
          getUser.rejected
        ),
        (state) => {
          state.error = true;
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          loginAdmin.pending,
          twoFactorAuth.pending,
          getUser.pending,
          resetPassword.pending,
          updateUserSettings.pending
        ),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      );
  },
});

export const {
  updateStage,
  updateField,
  resetFields,
  logoutAdmin,
  resetAuthError,
} = authSlice.actions;

export default authSlice.reducer;
