import routes from "../../routes";
import {
  HiOutlineBriefcase,
  HiViewGrid,
  HiOutlineViewGridAdd,
} from "react-icons/hi";
import {
  RiUserLine,
  RiUserSettingsLine,
  RiArrowLeftRightLine,
} from "react-icons/ri";
import {
  MdOutlineSavings,
  MdOutlineLocationOn,
  MdOutlineMarkEmailUnread,
  MdOutlineSwapHorizontalCircle,
  MdAddchart,
} from "react-icons/md";
import { BsShieldCheck, BsShieldLock } from "react-icons/bs";
import { AiOutlineIssuesClose, AiOutlineWarning } from "react-icons/ai";
import { RiShieldUserLine } from "react-icons/ri";
import { RxActivityLog } from "react-icons/rx";
import { CgProfile } from "react-icons/cg";
import { HiOutlineKey } from "react-icons/hi";
import { IoNotificationsOutline } from "react-icons/io5";
import { FiServer } from "react-icons/fi";
import { TbInbox } from "react-icons/tb";

export const dashBoards = {
  instance: {
    title: "Instance Dashboard",
    value: "instance",
  },
  exchanger: {
    title: "Exchanger Dashboard",
    value: "exchanger",
  },
  finswitch: {
    title: "Finswitch Dashboard",
    value: "finswitch",
  },
};

export const dashBoardLinks = {
  instance: [
    {
      title: "Dashboard",
      icon: <HiViewGrid />,
      link: routes.protected.instance.dashboard,
    },
    {
      title: "Admin",
      icon: <HiOutlineBriefcase />,
      link: routes.protected.instance.admin,
    },
    {
      title: "Story Update",
      icon: <RiUserSettingsLine />,
      link: routes.protected.instance.story_update,
    },
    {
      title: "Transactions",
      icon: <RiArrowLeftRightLine />,
      link: routes.protected.instance.transactions,
    },
    {
      title: "Savings",
      icon: <MdOutlineSavings />,
      link: routes.protected.instance.savings,
    },
    {
      title: "Users",
      icon: <RiUserLine />,
      link: routes.protected.instance.users,
    },
    {
      title: "Dispute & Issues",
      icon: <AiOutlineIssuesClose />,
      link: routes.protected.instance.dispute_and_issues,
    },
    {
      title: "Settings",
      icon: <BsShieldCheck />,
      link: routes.protected.instance.settings,
    },
  ],
  exchanger: [
    {
      title: "Dashboard",
      icon: <HiViewGrid />,
      link: routes.protected.exchanger.dashboard,
    },
    {
      title: "Exchangers",
      icon: <RiUserLine />,
      link: routes.protected.exchanger.users,
    },
    {
      title: "Locations",
      icon: <MdOutlineLocationOn />,
      link: routes.protected.exchanger.locations,
    },
    {
      title: "Pool Summary",
      icon: <MdAddchart />,
      link: routes.protected.exchanger.pool_summary,
    },
    {
      title: "P2P",
      icon: <MdAddchart />,
      link: routes.protected.exchanger.p2p,
    },
    {
      title: "Transactions",
      icon: <RiArrowLeftRightLine />,
      link: routes.protected.exchanger.transactions,
    },
    {
      title: "Frozen Wallet",
      icon: <AiOutlineWarning />,
      link: routes.protected.exchanger.frozen_wallet,
    },
    {
      title: "Dispute",
      icon: <RiUserLine />,
      link: routes.protected.exchanger.dispute_and_issues,
    },
  ],
  finswitch: [
    {
      title: "Dashboard",
      icon: <HiViewGrid />,
      link: routes.protected.finswitch.dashboard,
    },
    {
      title: "Merchant",
      icon: <RiUserLine />,
      link: routes.protected.finswitch.merchants,
    },
    {
      title: "Transactions",
      icon: <RiArrowLeftRightLine />,
      link: routes.protected.finswitch.transactions,
    },
    {
      title: "Application",
      icon: <HiOutlineViewGridAdd className="rotate-[270deg]" />,
      link: routes.protected.finswitch.applications,
    },
    {
      title: "Industry",
      icon: <HiOutlineBriefcase />,
      link: routes.protected.finswitch.industries,
    },
  ],
};

export const instanceDasboardSettingsNavigation = [
  {
    title: "General Settings",
    icon: <RiShieldUserLine fontSize={20} />,
    link: routes.protected.instance.general_settings,
    isToggle: false,
  },
  {
    title: "Admin Log",
    icon: <RxActivityLog fontSize={20} />,
    link: routes.protected.instance.settings,
    isToggle: false,
  },
  {
    title: "Profile",
    icon: <CgProfile fontSize={20} />,
    link: routes.protected.instance.profile,
    isToggle: false,
  },
  {
    title: "Reset Password",
    icon: <HiOutlineKey fontSize={20} />,
    link: routes.protected.instance.reset_password,
    isToggle: false,
  },
  {
    title: "In app notification",
    icon: <IoNotificationsOutline fontSize={20} />,
    isToggle: true,
    state_name: "in_app_notification",
    toggleHandler() {},
  },
  {
    title: "Email notifications",
    icon: <MdOutlineMarkEmailUnread fontSize={20} />,
    isToggle: true,
    state_name: "email_notification",
    toggleHandler() {},
  },
  {
    title: "Two factor authentication",
    icon: <BsShieldCheck fontSize={20} />,
    state_name: "two_fa",
    isToggle: true,
    toggleHandler() {},
  },
  {
    title: "Google user authentication setting",
    icon: <BsShieldLock fontSize={20} />,
    isToggle: true,
    state_name: "google_two_fa",
    toggleHandler() {},
  },
];

export const withdrawalAndFundingSettings = [
  {
    title: "Withdrawal Setting",
    warning: true,
    warningText:
      "Please note that if you turn off withdrawal, no user will be able to  withdraw from their wallet",
  },
  {
    title: "Funding Settings",
    warning: true,
    warningText:
      "Please note that if you turn off Funding, no user will be able to Fund their wallet",
  },
];

export const generalSettingsNavigation = [
  {
    title: "Withdraw & funding settings",
    icon: <MdOutlineSwapHorizontalCircle />,
    path: routes.protected.instance.withdrawal_and_funding_settings,
  },
  {
    title: "Funding & Withdrawal limit settings",
    icon: <FiServer />,
    path: routes.protected.instance.funding_and_withdrawal_limit_settings,
  },
  {
    title: "Gas fee settings",
    icon: <TbInbox />,
    path: routes.protected.instance.gas_fee_settings,
  },
];
