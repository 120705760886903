import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../common/components/CustomToast";
import service from "../../common/service/protected";

export const createStory = createAsyncThunk<any, any>(
  "stories/createStory",
  async (data, thunkAPI) => {
    return service
      .createaStory(data)
      .then((res) => res.data)
      .then(() => thunkAPI.dispatch(getAllStories()))
      .catch((err) => customToast(err.message, true));
  }
);
export const getAllStories = createAsyncThunk(
  "stories/getAllStories",
  async (_, { rejectWithValue }) => {
    return service
      .retriveAllStories()
      .then((res) => res.data)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err.message);
      });
  }
);
export const getStoryViews = createAsyncThunk(
  "stories/getStoriesViews",
  async (data) => {
    return service
      .retriveStoryViews(data)
      .then((res) => res.data)
      .catch((err) => customToast(err.message, true));
  }
);
export const deleteStory = createAsyncThunk(
  "stories/deleteStory",
  async (id: string) => {
    return service
      .deleteaStory(id)
      .then((res) => res.data)
      .catch((err) => customToast(err.message, true));
  }
);
export const getStory = createAsyncThunk(
  "stories/getStory",
  async (id: string) => {
    return service
      .getaStory(id)
      .then((res) => res.data)
      .catch((err) => customToast(err.message, true));
  }
);
