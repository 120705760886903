import {
  Controller,
  Control,
  FieldError,
  Merge,
  FieldErrorsImpl,
} from "react-hook-form";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import { BsCheck2 } from "react-icons/bs";

type props = {
  labelText: string;
  required?: boolean;
  options: { text: string; value: any }[];
  name: string;
  control: Control<any, any>;
  error?:
    | Merge<
        FieldError,
        FieldErrorsImpl<{
          text: string;
          value: any;
        }>
      >
    | undefined;
};

export default function DashboardSelect({
  labelText,
  options,
  control,
  name,
  error,
  required,
}: props) {
  return (
    <div className="flex flex-col gap-y-2">
      <label htmlFor="" className="text-[#090914] font-medium inline-block">
        {labelText}
        {required && "*"}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <Listbox onChange={(e) => onChange(e)} value={value} {...rest}>
            <div className="relative">
              <Listbox.Button className="relative  bg-[#F8FAFC] outline-none focus:ring-2  focus:ring-offset-0 hover:ring-opacity-30 transition duration-150 border border-[#CBD5E1] rounded-xl w-full h-[3rem] flex-shrink-0 px-3 text-left">
                <span className="text-gray-900">{value.text}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <TiArrowSortedDown />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map(({ text, value }) => (
                    <Listbox.Option
                      key={value}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-[#293B96] bg-opacity-10 text-[#293B96] text-opacity-100 "
                            : "text-gray-900"
                        }`
                      }
                      value={{ text, value }}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {text}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#293B96]">
                              <BsCheck2
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        )}
      />
      <span className="  h-2 block lowercase text-xs text-red-500">
        {error && error.message}
      </span>
    </div>
  );
}
