import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import SideModal from "common/components/CustomModal/SideModal";
import { DashboardInput } from "common/components/DashboardComponents/Inputs/dashbaord-input";
import CustomButtom from "common/components/CustomButton";
import { CreateIndustryStateType } from "types/form-state-types";
import { createIndustrySchema } from "common/form-schema/create-industry";
import { finswitchServices as service } from "common/service/protected";
import customToast from "common/components/CustomToast";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { getAllIndustries } from "store/industries/industriesAsyncThunk";

const CreateIndustry = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<CreateIndustryStateType>({
    resolver: yupResolver(createIndustrySchema),
  });

  const handleCreateIndustry: SubmitHandler<CreateIndustryStateType> = async (
    data
  ) => {
    try {
      setLoading(true);
      const response = await service.createIndustry(data);
      dispatch(getAllIndustries({}));
      customToast(response?.message || "Industry Created Sussessfully");
      reset();
    } catch (error: any) {
      customToast(error?.message || "Something went wrong", true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SideModal
      className="p-[4.25rem]"
      clickBackDrop={() => navigate(-1)}
      position={undefined}
    >
      <header className="pb-2 border-b border-gray-700">
        <h4 className="text-4xl font-medium text-base-300">Create Industry</h4>
      </header>
      <section className="pt-[18px] pb-12">
        <p className="text-base leading-5 text-gray-600 mb-[1.813rem]">
          Input the details to create industry on this platform
        </p>
        <div className="">
          <form
            className="flex flex-col gap-y-5"
            onSubmit={handleSubmit(handleCreateIndustry)}
          >
            <DashboardInput
              labelText="Industry name"
              register={register("name")}
              required
              error={errors.name}
            />

            <DashboardInput
              type="textarea"
              labelText="Description"
              register={register("description")}
              required
              error={errors.description}
            />
            <div className="mt-4">
              <CustomButtom className="w-[13.688rem]" disable={loading}>
                Submit
              </CustomButtom>
            </div>
          </form>
        </div>
      </section>
    </SideModal>
  );
};

export default CreateIndustry;
