import { createSlice } from "@reduxjs/toolkit";
import {
  BusinessDocumentType,
  BusinessProfileType,
  ComplianceDocumentType,
  PolicyType,
} from "module/finswitch/pages/Merchant/types/business-profile.types";
import { ApplicationType } from "types/application-types";
import {
  getAllMerchantApps,
  getComplianceDocs,
  getComplianceStakeHolders,
  retrieveMerchant,
  updateComplianceDocs,
  approveMerchant,
  updateMerchantStatus,
  getAllPolicy,
  updatePolicy,
} from "./merchantInfoAsyncThunk";

type MerchantInfoState = {
  businessProfile: {
    loading: boolean;
    data: BusinessProfileType | null;
  };
  complianceDocuments: {
    loading: boolean;
    data: ComplianceDocumentType[];
  };
  apps: {
    loading: boolean;
    data: ApplicationType[];
  };
  businessOwnerShip: {
    loading: boolean;
    data: BusinessDocumentType[];
  };
  policy: {
    loading: boolean;
    data: PolicyType[];
  };
};

const initialState: MerchantInfoState = {
  businessProfile: {
    loading: false,
    data: null,
  },
  apps: {
    loading: false,
    data: [],
  },
  complianceDocuments: {
    data: [],
    loading: false,
  },
  businessOwnerShip: {
    data: [],
    loading: false,
  },
  policy: {
    data: [],
    loading: false,
  },
};

const merchantInfoSlice = createSlice({
  name: "merchantInfo",
  initialState,
  reducers: {
    resetMerchantInfo(state) {
      state = initialState;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getAllMerchantApps.pending, (state) => {
      state.apps.loading = true;
    });
    builders.addCase(getAllMerchantApps.rejected, (state) => {
      state.apps.loading = false;
    });
    builders.addCase(getAllMerchantApps.fulfilled, (state, action) => {
      state.apps.data = action.payload.data;
      state.apps.loading = false;
    });

    builders.addCase(retrieveMerchant.pending, (state) => {
      state.businessProfile.loading = true;
    });

    builders.addCase(retrieveMerchant.fulfilled, (state, action) => {
      state.businessProfile.data = action.payload.data;
      state.businessProfile.loading = false;
    });

    builders.addCase(retrieveMerchant.rejected, (state) => {
      state.businessProfile.loading = false;
    });

    builders.addCase(getComplianceStakeHolders.pending, (state) => {
      state.businessOwnerShip.loading = true;
    });

    builders.addCase(getComplianceStakeHolders.fulfilled, (state, action) => {
      state.businessOwnerShip.data = action.payload.data;
      state.businessOwnerShip.loading = false;
    });

    builders.addCase(getComplianceStakeHolders.rejected, (state) => {
      state.businessOwnerShip.loading = false;
    });

    builders.addCase(getComplianceDocs.pending, (state) => {
      state.complianceDocuments.loading = true;
    });

    builders.addCase(getComplianceDocs.fulfilled, (state, action) => {
      state.complianceDocuments.data = action.payload.data;
      state.complianceDocuments.loading = false;
    });

    builders.addCase(getComplianceDocs.rejected, (state) => {
      state.complianceDocuments.loading = false;
    });

    builders.addCase(updateComplianceDocs.fulfilled, (state, action) => {
      const updatedDocs = state.complianceDocuments.data.find(
        (item) => item._id === action.payload?.data?._id
      );

      if (updatedDocs) {
        updatedDocs.status = action.payload?.data?.status;
      }
    });

    builders.addCase(approveMerchant.fulfilled, (state) => {
      if (state.businessProfile.data) {
        state.businessProfile.data.admin_approved = true;
      }
    });
    builders.addCase(updateMerchantStatus.fulfilled, (state, action) => {
      if (state.businessProfile.data) {
        state.businessProfile.data.is_active = Boolean(
          action.payload.is_active
        );
      }
    });

    builders.addCase(getAllPolicy.pending, (state) => {
      state.policy.loading = true;
    });

    builders.addCase(getAllPolicy.fulfilled, (state, action) => {
      state.policy.data = action.payload.data;
      state.policy.loading = false;
    });

    builders.addCase(getAllPolicy.rejected, (state) => {
      state.policy.loading = false;
    });

    builders.addCase(updatePolicy.fulfilled, (state, action) => {
      const policyToUpdate = state.policy.data.find(
        (policy) => policy._id === action.payload.policyId
      );

      if (policyToUpdate) {
        policyToUpdate.is_active = Boolean(action.payload?.data?.is_active);
      }
    });
  },
});

export const { resetMerchantInfo } = merchantInfoSlice.actions;
export default merchantInfoSlice.reducer;
