import React from "react";
import { Route } from "react-router-dom";
import Auth from "../components/Layout/Auth";
import Protected from "../components/Layout/Protected";
import SuspsenseFallBack from "../components/SuspsenseFallBack";
import { GenerateRouteType } from "../../types";

/** function to generate routes */
export const generateRoute = ({
  path,
  Component,
  access,
  isSpecialRoute,
}: GenerateRouteType) => {
  return (
    <Route
      key={path}
      element={
        access === "guest-only" ? (
          <Auth />
        ) : (
          <Protected isSpecialRoute={isSpecialRoute || false} />
        )
      }
    >
      <Route
        path={path}
        element={
          <React.Suspense fallback={<SuspsenseFallBack />}>
            <Component />
          </React.Suspense>
        }
      />
    </Route>
  );
};
