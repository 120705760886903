import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { LocalDataType } from "types";
import { ApplicationType } from "types/application-types";
import { deleteApp, getAllApps, updateApp } from "./applicationsAsyncThunk";

const initialState: LocalDataType<ApplicationType[]> = {
  error: "",
  loading: false,
  data: [],
  pagination: {
    currentPage: 1,
    limit: 20,
    totalItems: 1,
  },
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<{ page: number }>) {
      state.pagination.currentPage = action.payload.page;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getAllApps.fulfilled, (state, action) => {
      state.data = action.payload?.data || [];
      state.pagination.totalItems = action.payload?.results || 1;
      state.error = "";
      state.loading = false;
    });

    builders.addCase(deleteApp.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item._id !== action.payload);
      state.error = "";
      state.loading = false;
    });

    builders.addCase(updateApp.fulfilled, (state) => {
      state.error = "";
      state.loading = false;
    });

    builders.addMatcher(
      isAnyOf(getAllApps.pending, deleteApp.pending, updateApp.pending),
      (state) => {
        state.loading = true;
      }
    );

    builders.addMatcher(
      isAnyOf(getAllApps.rejected, deleteApp.rejected, updateApp.rejected),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const { setCurrentPage } = applicationSlice.actions;

export default applicationSlice.reducer;
