import { useForm, SubmitHandler } from "react-hook-form";
import CustomButtom from "common/components/CustomButton";
import SideModal from "common/components/CustomModal/SideModal";
import { DashboardInput } from "common/components/DashboardComponents/Inputs/dashbaord-input";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { updateComplianceDocs } from "store/merchant-info/merchantInfoAsyncThunk";

const UpdateComplianceDocs = () => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm<{ reason: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { compliance_id: id } = useParams();

  const handleMerchantStatus: SubmitHandler<{ reason: string }> = ({
    reason,
  }) => {
    if (!id) return;
    setLoading(true);
    dispatch(
      updateComplianceDocs({ id, data: { status: "REJECTED", reason } })
    ).then(() => {
      reset();
      setLoading(false);
    });
  };

  return (
    <SideModal
      className="p-[4.25rem]"
      clickBackDrop={() => navigate(-1)}
      position={undefined}
    >
      <header className="pb-2 border-b border-gray-700">
        <h4 className="text-2xl font-medium text-base-300">
          Update Compliance Document Status
        </h4>
      </header>
      <section className="pt-[18px] pb-12">
        <div className="">
          <form
            className="flex flex-col gap-y-5"
            onSubmit={handleSubmit(handleMerchantStatus)}
          >
            <DashboardInput
              type="textarea"
              labelText="Reason For Rejecting Compliance Document"
              register={register("reason", { required: true })}
            />
            <div className="mt-4">
              <CustomButtom className="w-[13.688rem]" disable={loading}>
                Submit
              </CustomButtom>
            </div>
          </form>
        </div>
      </section>
    </SideModal>
  );
};

export default UpdateComplianceDocs;
