import React from "react";

interface DurationProps {
  value: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

function Duration({ value, onChange }: DurationProps) {
  const getMinutes = () => {
    const res = [];
    for (let i = 10; i <= 60; i++) {
      res.push({ value: i, name: `${i} minutes` });
    }
    return res;
  };
  const getHours = () => {
    const res = [];
    for (let i = 2; i <= 24; i++) {
      res.push({ value: `${i * 60}`, name: `${i} hours` });
    }
    return res;
  };
  const getDays = () => {
    const res = [];
    for (let i = 2; i <= 100; i++) {
      res.push({ value: `${i * 60 * 24}`, name: `${i} days` });
    }
    return res;
  };

  return (
    <select
      placeholder="Duration"
      name="duration_in_mins"
      className="text-base-150 py-5 pl-2 pr-9 bg-white border border-solid border-gray-400 rounded flex-1"
      value={value}
      onChange={onChange}
    >
      <option disabled>Duration</option>
      {getMinutes().map((itm, idx) => (
        <option key={idx} value={itm.value}>
          {itm.name}
        </option>
      ))}
      {getHours().map((itm, idx) => (
        <option key={idx} value={itm.value}>
          {itm.name}
        </option>
      ))}
      {getDays().map((itm, idx) => (
        <option key={idx} value={itm.value}>
          {itm.name}
        </option>
      ))}
    </select>
  );
}

export default Duration;
