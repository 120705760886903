import React from "react";

interface TimeProps {
  value: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

function Time({ value, onChange }: TimeProps) {
  const timeHandler = () => {
    const res = [];

    for (let i = 0; i < 24; i++) {
      res.push({ name: `${i}:00`, value: `${i}:00` });
    }
    return res;
  };
  return (
    <select
      placeholder="Role Permission"
      name="time"
      className="text-base-150 py-5 pl-2 pr-9 bg-white border border-solid border-gray-400 rounded flex-1"
      value={value}
      onChange={onChange}
    >
      <option disabled>10:00pm</option>
      {timeHandler().map((itm, idx) => (
        <option key={idx} value={itm.value}>
          {itm.name}
        </option>
      ))}
    </select>
  );
}

export default Time;
