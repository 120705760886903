import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "common/components/CustomToast";
import service from "common/service/protected";
import { RequestBodyType } from "types";

export const getAllTransactions = createAsyncThunk<any, RequestBodyType>(
  "transactions/getAllTransactions",
  async (data, { rejectWithValue }) => {
    return service
      .getAllTransactions(data)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const getAllBankFormats = createAsyncThunk<any, RequestBodyType>(
  "transactions/getAllBankFormats",
  async (data, { rejectWithValue }) => {
    return service
      .getAllBankFormats(data)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const getAllAssets = createAsyncThunk<any, RequestBodyType>(
  "transactions/getAllAssets",
  async (data, { rejectWithValue }) => {
    return service
      .getAllAssets(data)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const getAsset = createAsyncThunk<any, string>(
  "transactions/getAsset",
  async (data, { rejectWithValue }) => {
    return service
      .getAsset(data)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);
