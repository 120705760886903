import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "common/components/CustomToast";
import { exchangerServices as services } from "common/service/protected";
import {
  BlockExchangerType,
  GetExchangersType,
  SearchExchangersType,
  UnblockExchangerType,
} from "types/exchanger";

export const getExchangers = createAsyncThunk<any, GetExchangersType>(
  "exchangers/getExchangers",
  async (data, { rejectWithValue }) => {
    return services
      .getExchangers(data)
      .then((res) => res)
      .catch((error) => {
        customToast(error.message || "Something went wrong", true);
        return rejectWithValue(error.message);
      });
  }
);

export const searchExchangers = createAsyncThunk<any, SearchExchangersType>(
  "exchangers/searchExchangers",
  async (data, { rejectWithValue }) => {
    return services
      .searchExchangers(data)
      .then((res) => res)
      .catch((error) => {
        customToast(error.message || "Something went wrong", true);
        return rejectWithValue(error.message);
      });
  }
);

export const blockExchanger = createAsyncThunk<any, BlockExchangerType>(
  "exchangers/blockExchanger",
  async (data, { rejectWithValue }) => {
    return services
      .blockExchanger(data)
      .then((res) => {
        customToast(res.data || "Exchanger Blocked Successfully");
        return res;
      })
      .catch((error) => {
        customToast(error.message || "Something went wrong", true);
        return rejectWithValue(error.message);
      });
  }
);

export const blockExchangerFunding = createAsyncThunk<any, BlockExchangerType>(
  "exchangers/blockExchangerFunding",
  async (data, { rejectWithValue }) => {
    return services
      .blockExchangerFunding(data)
      .then((res) => {
        customToast(res.data || "Funding Blocked Successfully");
        return res;
      })
      .catch((error) => {
        customToast(error.message || "Something went wrong", true);
        return rejectWithValue(error.message);
      });
  }
);

export const blockExchangerWithdrawal = createAsyncThunk<
  any,
  BlockExchangerType
>("exchangers/blockExchangerWithdrawal", async (data, { rejectWithValue }) => {
  return services
    .blockExchangerWithdrawal(data)
    .then((res) => {
      customToast(res.data || "Withdrawal Blocked Successfully");
      return res;
    })
    .catch((error) => {
      customToast(error.message || "Something went wrong", true);
      return rejectWithValue(error.message);
    });
});

export const unblockExchanger = createAsyncThunk<any, UnblockExchangerType>(
  "exchangers/unblockExchanger",
  async (data, { rejectWithValue }) => {
    return services
      .unblockExchanger(data)
      .then((res) => {
        customToast(res.data || "Exchanger Unblocked Successfully");
        return res;
      })
      .catch((error) => {
        customToast(error.message || "Something went wrong", true);
        return rejectWithValue(error.message);
      });
  }
);

export const unblockExchangerFunding = createAsyncThunk<
  any,
  UnblockExchangerType
>("exchangers/unblockExchangerFunding", async (data, { rejectWithValue }) => {
  return services
    .unblockExchangerFunding(data)
    .then((res) => {
      customToast(res.data || "Funding Unblocked Successfully");
      return res;
    })
    .catch((error) => {
      customToast(error.message || "Something went wrong", true);
      return rejectWithValue(error.message);
    });
});

export const unblockExchangerWithdrawal = createAsyncThunk<
  any,
  UnblockExchangerType
>(
  "exchangers/unblockExchangerWithdrawal",
  async (data, { rejectWithValue }) => {
    return services
      .unblockExchangerWithdrawal(data)
      .then((res) => {
        customToast(res.data || "Exchanger Unblocked Successfully");
        return res;
      })
      .catch((error) => {
        customToast(error.message || "Something went wrong", true);
        return rejectWithValue(error.message);
      });
  }
);
