import React from "react";

interface BackDropProps {
  children: React.ReactNode;
  handleClose(): void;
  className?: string;
}

function BackDrop({ children, handleClose, className }: BackDropProps) {
  return (
    <div
      onClick={handleClose}
      className=" fixed top-0 left-0 h-full bg-base-4 w-full flex  justify-center items-center z-20"
    >
      <section
        className={`${
          className ? className : ""
        } bg-white rounded h-auto p-10 flex flex-col justify-center items-center top-1/2 left-1/2 translate`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </section>
    </div>
  );
}

export default BackDrop;
