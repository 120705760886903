import {
  APIResponseModel,
  AdminType,
  AssetType,
  BankFormatType,
  RequestBodyType,
  SavingsPlanType,
  TransactionType,
  UserType,
} from "../../../types";
import {
  GetExchangersType,
  SearchExchangersType,
  BlockExchangerType,
  UnblockExchangerType,
  ExchangerTransactionType,
} from "../../../types/exchanger";
import { ENDPOINTS } from "../config/enpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeRequestWithFormData,
} from "../request";

const service = {
  async createaStory(data: any, images?: any) {
    const { method, url } = ENDPOINTS.stories.create_a_story;
    return makeRequestWithFormData(method, url, data, true);
  },
  async retriveAllStories(data?: any) {
    const { method, url } = ENDPOINTS.stories.retrive_all_stories;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async retriveStoryViews(data: any) {
    const { method, url } = ENDPOINTS.stories.retrive_story_views;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async deleteaStory(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.stories.delete_a_story;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getaStory(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.stories.get_a_story;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async retriveAllAdmins(
    data: RequestBodyType = {}
  ): Promise<APIResponseModel<AdminType>> {
    const { method, url } = ENDPOINTS.admins.retrive_all_admins;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async retriveSingleAdmin(id: string) {
    const { method, url: makeurl } = ENDPOINTS.admins.retrive_single_admin;
    const url = makeurl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async deactivateAnAdmin(data: any) {
    const { method, url } = ENDPOINTS.admins.deactivate_admin;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async retriveAllUsers(
    data: RequestBodyType = {}
  ): Promise<APIResponseModel<UserType[]>> {
    const { method, url } = ENDPOINTS.admins.retrive_all_users;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async retriveSingleUser(id: string) {
    const { method, url: makeurl } = ENDPOINTS.admins.retrive_single_user;
    const url = makeurl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async toggleUserPermission(data: any) {
    const { method, url } = ENDPOINTS.admins.toggle_user_permission;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async toggleUserStatus(data: { is_active: boolean; user_id: string }) {
    const { method, url } = ENDPOINTS.admins.toggle_user_status;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async getAllTransactions(
    data: RequestBodyType = {}
  ): Promise<APIResponseModel<TransactionType[]>> {
    const { method, url } = ENDPOINTS.transactions.get_all_transactions;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getAllAssets(
    data: RequestBodyType = {}
  ): Promise<APIResponseModel<AssetType[]>> {
    const { method, url } = ENDPOINTS.transactions.get_all_assets;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async createAsset(data: any) {
    const { method, url } = ENDPOINTS.transactions.create_asset;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async deleteAsset(data: string) {
    const { method, url } = ENDPOINTS.transactions.delete_asset;
    return makeAuthorizedRequestWithHeadersAndPayload(method, `${url(data)}`);
  },

  async getAsset(data: string): Promise<APIResponseModel<AssetType>> {
    const { method, url } = ENDPOINTS.transactions.view_asset;
    return makeAuthorizedRequestWithHeadersAndPayload(method, `${url(data)}`);
  },

  async getAllBankFormats(
    data: RequestBodyType = {}
  ): Promise<APIResponseModel<BankFormatType[]>> {
    const { method, url } = ENDPOINTS.transactions.get_all_bank_formats;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getAllSavingsPlan(
    data: RequestBodyType = {}
  ): Promise<APIResponseModel<SavingsPlanType[]>> {
    const { method, url } = ENDPOINTS.savings.get_all_savings_plan;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async createSavingsPlan(data: SavingsPlanType) {
    const { method, url } = ENDPOINTS.savings.create_savings_plan;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async authenticatorGetStarted() {
    const { method, url } = ENDPOINTS.authenticator.get_started;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },

  async enableGoogleAuthenticator(data: { code: string }) {
    const { method, url } = ENDPOINTS.authenticator.verify_code;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export const finswitchServices = {
  // Finswitch Services

  async getMerchants(data: RequestBodyType = {}, status: boolean) {
    const { method, url } = ENDPOINTS.merchants.list_merchants;
    return makeAuthorizedRequestWithHeadersAndPayload(
      method,
      url(status, data.search?.name, data?.page, data?.limit)
    );
  },

  async approveMerchantAccount(data: { id: string }) {
    const { method, url } = ENDPOINTS.merchants.approve_merchant;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(data.id));
  },

  async updateMerchant(
    id: string,
    data: {
      reason?: string;
      is_active: boolean;
    }
  ) {
    const { method, url } = ENDPOINTS.merchants.update_merchant_status;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id), data);
  },

  async updateComplianceDoc(
    id: string,
    data: {
      reason?: string;
      status: "REJECTED" | "APPROVED";
    }
  ) {
    const { method, url } = ENDPOINTS.merchants.update_compliance_doc;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id), data);
  },

  async getIndustries(data: RequestBodyType = {}) {
    const { method, url } = ENDPOINTS.industries.get;
    return makeAuthorizedRequestWithHeadersAndPayload(
      method,
      url(data?.page, data?.limit, data?.search?.name)
    );
  },

  async createIndustry(data: { description: string; name: string }) {
    const { method, url } = ENDPOINTS.industries.create;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async deleteIndustry(id: string) {
    const { method, url } = ENDPOINTS.industries.delete;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id));
  },

  async getAllApps(data: RequestBodyType = {}) {
    const { method, url } = ENDPOINTS.merchants.list_apps;
    return makeAuthorizedRequestWithHeadersAndPayload(
      method,
      url(
        data.search?.company_id,
        data?.limit,
        data?.page,
        data?.search?.name,
        data?.search?.is_active
      )
    );
  },

  async deleteApp(id: string) {
    const { method, url } = ENDPOINTS.merchants.delete_app;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id));
  },

  async updateApp(
    id: string,
    data: {
      reason?: string;
      is_active: boolean;
    }
  ) {
    const { method, url } = ENDPOINTS.merchants.update_app_status;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id), data);
  },

  async getSingleApp(id: string) {
    const { method, url } = ENDPOINTS.merchants.retrieve_app;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id));
  },

  async getFinswitchTransactions(data: RequestBodyType = {}) {
    const { method, url } = ENDPOINTS.finswitch_transactions.get_all;
    const { search } = data;
    return makeAuthorizedRequestWithHeadersAndPayload(
      method,
      url(
        data.page,
        data.limit,
        search?.start_date,
        search?.end_date,
        search?.mode,
        search?.type,
        search?.status,
        search?.external_api_initiated,
        search?.name,
        search?.app_consumer_id,
        search?.merchant_app_id,
        search?.txn_reference
      )
    );
  },

  async getSingleTransaction(id: string) {
    const { method, url } = ENDPOINTS.finswitch_transactions.get_single;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id));
  },

  async retrieveMerchant(id: string) {
    const { method, url } = ENDPOINTS.merchants.retrieve_merchant;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id));
  },
  async getComplicanceDocs(id: string) {
    const { method, url } = ENDPOINTS.merchants.compliance.list;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id));
  },
  async getComplianceStakeHolders(id: string) {
    const { method, url } = ENDPOINTS.merchants.compliance.list_shareholders;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(id));
  },

  getAllAppPolicy(appId: string) {
    const { method, url } = ENDPOINTS.merchants.list_policy;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url(appId));
  },

  retrieveSinglePolicy(appId: string, policyId: string) {
    const { method, url } = ENDPOINTS.merchants.retrieve_policy;
    return makeAuthorizedRequestWithHeadersAndPayload(
      method,
      url(appId, policyId)
    );
  },

  async updatePolicy(
    appId: string,
    policyId: string,
    data: {
      is_active: boolean;
    }
  ) {
    const { method, url } = ENDPOINTS.merchants.update_policy;
    return makeAuthorizedRequestWithHeadersAndPayload(
      method,
      url(appId, policyId),
      data
    );
  },
};

export const exchangerServices = {
  async getExchangers(data: GetExchangersType) {
    const { method, url } = ENDPOINTS.exchanger.get_exchangers;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async searchExchangers(data: SearchExchangersType) {
    const { method, url } = ENDPOINTS.exchanger.search_exchangers;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async blockExchanger(data: BlockExchangerType) {
    const { method, url } = ENDPOINTS.exchanger.block_exchanger;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async blockExchangerWithdrawal(data: BlockExchangerType) {
    const { method, url } = ENDPOINTS.exchanger.block_withdrawal;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async blockExchangerFunding(data: BlockExchangerType) {
    const { method, url } = ENDPOINTS.exchanger.block_funding;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async unblockExchanger(data: UnblockExchangerType) {
    const { method, url } = ENDPOINTS.exchanger.unblock_exchanger;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async unblockExchangerWithdrawal(data: UnblockExchangerType) {
    const { method, url } = ENDPOINTS.exchanger.unblock_withdrawal;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async unblockExchangerFunding(data: UnblockExchangerType) {
    const { method, url } = ENDPOINTS.exchanger.unblock_funding;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getFundingTransactions(data: ExchangerTransactionType) {
    const { method, url } = ENDPOINTS.exchanger.get_funding_transactions;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getWithdrawalTransactions(data: ExchangerTransactionType) {
    const { method, url } = ENDPOINTS.exchanger.get_withdrawal_transactions;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getCompletedRequests(data: ExchangerTransactionType) {
    const { method, url } = ENDPOINTS.exchanger.get_completed_requests;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getProcessingRequests(data: ExchangerTransactionType) {
    const { method, url } = ENDPOINTS.exchanger.get_processing_requests;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getPendingRequests(data: ExchangerTransactionType) {
    const { method, url } = ENDPOINTS.exchanger.get_pending_requests;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getPoolSummary(data: ExchangerTransactionType) {
    const { method, url } = ENDPOINTS.exchanger.get_pool_summary;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getTrayRequests(data: ExchangerTransactionType) {
    const { method, url } = ENDPOINTS.exchanger.get_tray_requests;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getIssues(data: ExchangerTransactionType) {
    const { method, url } = ENDPOINTS.exchanger.get_issues;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default service;
