import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllUsers,
  toggleUserPermission,
  toggleUserStatus,
} from "./userAsyncThunk";
import { UsersType } from "../../types";

const initialState: UsersType = {
  loading: false,
  error: "",
  data: [],
  pagination: {
    currentPage: 1,
    limit: 20,
    totalItems: 1,
  },
  searchTerm: "",
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUserError: (state) => {
      state.error = initialState.error;
    },

    setCurrentPage(state, action: PayloadAction<{ pageNumber: number }>) {
      state.pagination.currentPage = action.payload.pageNumber;
    },

    setUsersSearchTerm(state, action: PayloadAction<{ searchTerm: string }>) {
      state.searchTerm = action.payload.searchTerm;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.data = action.payload?.data || [];
        state.pagination.totalItems = action.payload?.results || 1;
      })
      .addCase(toggleUserPermission.fulfilled, (state, action) => {
        const temp = state.data.map((user) => {
          if (user._id === action.payload._id) {
            return action.payload;
          }
          return user;
        });
        state.data = temp;
        if (state.error) {
          state.error = initialState.error;
        }
      })
      .addCase(toggleUserStatus.fulfilled, (state, action) => {
        const updatedUser = state.data.find(
          (user) => user._id === action.payload._id
        );

        if (updatedUser) {
          updatedUser.is_active = action.payload.is_active;
        }
        if (state.error) {
          state.error = initialState.error;
        }
      })
      .addCase(toggleUserPermission.rejected, (state, action) => {
        if (typeof action.payload === "string") {
          state.error = action.payload;
        }
      })
      .addMatcher(
        isAnyOf(
          getAllUsers.pending,
          toggleUserPermission.pending,
          toggleUserStatus.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getAllUsers.rejected,
          getAllUsers.fulfilled,
          toggleUserPermission.rejected,
          toggleUserPermission.fulfilled,
          toggleUserStatus.fulfilled,
          toggleUserStatus.rejected
        ),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export const { resetUserError, setCurrentPage } = usersSlice.actions;

export default usersSlice.reducer;
