import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import SideModal from "common/components/CustomModal/SideModal";
import { DashboardInput } from "common/components/DashboardComponents/Inputs/dashbaord-input";
import DashboardSelect from "common/components/DashboardComponents/Inputs/dashboard-select";
import CustomButtom from "common/components/CustomButton";
import { useForm, SubmitHandler } from "react-hook-form";
import { CreateBankFormatStateType } from "types/form-state-types";
import { createBankFormatSchema } from "common/form-schema/create-bank-format";

const COUNTRY = [{ text: "Nigeria", value: "nigeria" }];

const CreateBankFormat = () => {
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<CreateBankFormatStateType>({
    resolver: yupResolver(createBankFormatSchema),
    defaultValues: {
      country: COUNTRY[0],
    },
  });
  const navigate = useNavigate();

  const handleCreateBankFormat: SubmitHandler<CreateBankFormatStateType> = (
    data
  ) => {
    console.log(data);
  };

  return (
    <SideModal
      className="p-[4.25rem]"
      clickBackDrop={() => navigate(-1)}
      position={undefined}
    >
      <header className="pb-2 border-b border-gray-700">
        <h4 className="text-4xl font-medium text-base-300">Create Format</h4>
      </header>
      <section className="pt-[18px] pb-12">
        <p className="text-base leading-5 text-gray-600 mb-[1.813rem]">
          Input the details to create format on this platform
        </p>
      </section>
      <div className="">
        <form
          className="flex flex-col gap-y-5"
          onSubmit={handleSubmit(handleCreateBankFormat)}
        >
          <DashboardSelect
            labelText="Country of account"
            options={COUNTRY}
            control={control}
            name="country"
            required
            error={errors.country}
          />
          <DashboardInput
            labelText="Name"
            register={register("name")}
            error={errors.name}
            required
          />
          <div className="mt-4">
            <CustomButtom className="w-[13.688rem]">Submit</CustomButtom>
          </div>
        </form>
      </div>
    </SideModal>
  );
};

export default CreateBankFormat;
