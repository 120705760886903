import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "common/service/protected";
import customToast from "common/components/CustomToast";

export const authenticatorGetStarted = createAsyncThunk(
  "authenticator/authenticatorGetStarted",
  async (_, { rejectWithValue }) => {
    return service
      .authenticatorGetStarted()
      .then((res) => res.data)
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const enableGoogleAuthenticator = createAsyncThunk<
  any,
  { code: string }
>(
  "authenticator/enableGoogleAuthenticator",
  async (data, { rejectWithValue }) => {
    return service
      .enableGoogleAuthenticator(data)
      .then((response) => response.data)
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);
