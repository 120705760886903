const routes = {
  nonprotected: {
    login: "/auth/login",
    two_fa: "/auth/login/2fa",
    resetpassword: "/auth/resetpassword",
    forgotpassword: "/auth/forgotpassword",
    enter_otp: "/auth/enter_otp",
    new_password: "/auth/new_password",
    reset_successful: "/auth/reset_successful",
  },
  protected: {
    instance: {
      dashboard: "/instance/dashboard",
      admin: "/instance/admin",
      create_admin: "/instance/admin/create_admin",
      story_update: "/instance/story_update",
      story_update_action: "/instance/story_update/action",
      create_story_update: "/instance/story_update/create",
      analytics_story_update: "/instance/story_update/:storyid/analytics",
      users: "/instance/users",
      users_action: "/instance/users/action",
      transactions: "/instance/transactions",
      create_asset: "/instance/transactions/create_asset",
      view_asset: "/instance/transactions/asset/:id",
      create_bank_format: "/instance/transactions/create_bank_format",
      savings: "/instance/savings",
      create_saving: "/instance/savings/create_savings",
      savings_transactions: "/instance/savings/transactions",
      dispute_and_issues: "/instance/disputes",
      settings: "/instance/settings",
      general_settings: "/instance/settings/general_settings",
      withdrawal_and_funding_settings:
        "/instance/settings/general_settings/withdrawal_and_funding_settings",
      funding_and_withdrawal_limit_settings:
        "/instance/settings/general_settings/funding_and_withdrawal_limit_settings",
      gas_fee_settings: "/instance/settings/general_settings/gas_fee_settings",
      settings_action: "/instance/settings/settings_action",
      reset_password: "/instance/settings/reset_password",
      profile: "/instance/settings/profile",
      google_2fa: "/instance/settings/google_authenticator_setup",
    },
    exchanger: {
      dashboard: "/exchanger/dashboard",
      dispute_and_issues: "/exchanger/disputes",
      users: "/exchanger/users",
      transactions: "/exchanger/transactions",
      frozen_wallet: "/exchanger/frozen_wallets",
      locations: "/exchanger/locations",
      create_location: "/exchanger/locations/create_location",
      pool_summary: "/exchanger/pool_summary",
      p2p: "/exchanger/peer_to_peer",
      p2p_partners: "/exchanger/peer_to_peer/partners/:id",
      p2p_kyc: "/exchanger/peer_to_peer/merchant/kyc/:id",
      merchant_info: "/exchanger/users/:id",
    },
    finswitch: {
      dashboard: "/finswitch/dashboard",
      pending_documents: "/finswitch/dashboard/pending_documents",
      merchants: "/finswitch/merchants",
      merchant_info: "/finswitch/merchants/:id",
      transactions: "/finswitch/transactions",
      transaction_details: "/finswitch/transactions/:id",
      applications: "/finswitch/applications",
      update_app: "/finswitch/applications/:name/:id",
      update_compliance_doc:
        "/finswitch/merchants/:id/compliance/:compliance_id",
      update_single_merchant: "/finswitch/merchants/:id/update_status",
      update_merchant: "/finswitch/merchants/:name/:id",
      industries: "/finswitch/industries",
      create_industry: "/finswitch/industries/create_industry",
    },
  },
};

export default routes;
