import { APIResponseModel, UserSettingsOption } from "types";
import { ENDPOINTS } from "../config/enpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeUnauthorizedRequestWithHeadersAndPayload,
} from "../request";

const auth = {
  async registerAdmin(data: any) {
    const { method, url } = ENDPOINTS.auth.signup;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async loginAdmin(data: any): Promise<APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.auth.signin;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async twoFactorAuth(data: any) {
    const { method, url } = ENDPOINTS.auth.two_fa;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async updatePassword(data: any) {
    const { method, url } = ENDPOINTS.auth.update_password;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async forgotPassword(data: any) {
    const { method, url } = ENDPOINTS.auth.forgot_password;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async resetPassword(data: any) {
    const { method, url } = ENDPOINTS.auth.reset_password;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getAdmin() {
    const { method, url } = ENDPOINTS.auth.get_user;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateEmail(data: any) {
    const { method, url } = ENDPOINTS.auth.update_email;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async resendCode() {
    const { method, url } = ENDPOINTS.auth.send_verification_code;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async verifyUser(data: any) {
    const { method, url } = ENDPOINTS.auth.verify_user;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async createSuperAdmin(data: any) {
    const { method, url } = ENDPOINTS.auth.create_super_admin;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async createAdmin(data: any) {
    const { method, url } = ENDPOINTS.auth.create_admin;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async deactivateAdmin(data: any) {
    const { method, url } = ENDPOINTS.auth.deactivate_admin;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async refreshToken() {
    const { method, url } = ENDPOINTS.auth.refresh_access_token;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },

  async updateUserSettings(data: UserSettingsOption) {
    const { method, url } = ENDPOINTS.auth.update_user_settings;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default auth;
