import { useForm, SubmitHandler } from "react-hook-form";
import SideModal from "common/components/CustomModal/SideModal";
import DashboardSelect from "common/components/DashboardComponents/Inputs/dashboard-select";
import { useNavigate } from "react-router-dom";
import CustomButtom from "common/components/CustomButton";
import { CreateLocationStateType } from "types/form-state-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLocationSchema } from "common/form-schema/create-location";

const COUNTRY = [
  { text: "Nigeria", value: "Nigeria" },
  { text: "Ghana", value: "Ghana" },
  { text: "United States", value: "United States" },
  { text: "Saudi Arabia", value: "Saudi Arabia" },
];

const CreateLocation = () => {
  const navigate = useNavigate();

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<CreateLocationStateType>({
    resolver: yupResolver(createLocationSchema),
    defaultValues: {
      location: COUNTRY[0],
    },
  });

  const handleCreateLocation: SubmitHandler<CreateLocationStateType> = (
    data
  ) => {
    console.log(data);
  };

  return (
    <SideModal
      className="p-[4.25rem]"
      clickBackDrop={() => navigate(-1)}
      position={undefined}
    >
      <header className="pb-2 border-b border-gray-700">
        <h4 className="text-4xl font-medium text-base-300">Create Location</h4>
      </header>
      <section className="pt-[18px] pb-12">
        <p className="text-base leading-5 text-gray-600 mb-[1.813rem]">
          Input the details to create location on this platform
        </p>
        <div className="">
          <form
            className="flex flex-col gap-y-5"
            onSubmit={handleSubmit(handleCreateLocation)}
          >
            <DashboardSelect
              labelText="Choose Location"
              options={COUNTRY}
              control={control}
              name="location"
              error={errors.location}
            />
            <div className="mt-4">
              <CustomButtom className="w-[13.688rem]">Submit</CustomButtom>
            </div>
          </form>
        </div>
      </section>
    </SideModal>
  );
};

export default CreateLocation;
