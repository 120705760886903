import { HiBell, HiChevronDown, HiUserCircle } from "react-icons/hi";
import { useAppSelector } from "../../../../store/hooks";
import logo from "static/images/coloredlogo.svg";
import { useLocation } from "react-router-dom";

interface HeaderProps {
  className?: string;
  isSpecialRoute?: boolean;
}

function Header({ className, isSpecialRoute }: HeaderProps) {
  const firstname = useAppSelector(
    (state) => state?.auth?.admin.fullname?.split(" ")[0]
  );

  const { pathname } = useLocation();

  const currentPath = pathname
    .slice(pathname.lastIndexOf("/") + 1)
    .toLowerCase()
    .replaceAll("_", " ");

  return (
    <header
      className={`${
        className ? className : ""
      } flex justify-between items-center pt-9 pb-6 px-10  `}
    >
      <div className="flex gap-x-[12rem]">
        <div className="row-start-1 row-end-2 h-9">
          <img src={logo} alt="instance logo" />
        </div>
        {!isSpecialRoute && (
          <h1 className="text-[1.5rem]  font-bold  text-[#1B1919] capitalize">
            {currentPath}
          </h1>
        )}
      </div>
      <div className="flex  gap-x-10">
        <HiBell className="text-gray-200" size="2rem" />
        <div className="flex items-center gap-x-2 cursor-pointer">
          <HiUserCircle size="2rem" />
          <p className="font-black text-base leading-[19px]">{firstname}</p>
          <HiChevronDown className="ml-1" />
        </div>
      </div>
    </header>
  );
}

export default Header;
