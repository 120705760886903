import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "common/components/CustomToast";
import { finswitchServices as service } from "common/service/protected";
import { RequestBodyType } from "types";

export const getAllIndustries = createAsyncThunk<any, RequestBodyType>(
  "indutries/getAllIndustries",
  async (data, { rejectWithValue }) => {
    return service
      .getIndustries(data)
      .then((res) => res)
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);
