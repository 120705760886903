import { ExchangerModals } from "module/exchanger/modals";
import { FinswitchModals } from "module/finswitch/modals";
import { InstanceModals } from "module/instance/modals";
import { useAppSelector } from "store/hooks";

export const AppModals = () => {
  const currentDashboard = useAppSelector(
    (state) => state.dashboard.currentDashboard
  );

  const renderModals = () => {
    let template: JSX.Element | null;

    switch (currentDashboard) {
      case "instance":
        template = <InstanceModals />;
        break;

      case "exchanger":
        template = <ExchangerModals />;
        break;

      case "finswitch":
        template = <FinswitchModals />;
        break;

      default:
        template = null;
    }

    return template;
  };

  return renderModals();
};
