import React, { useEffect } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AlertModal from "../../../../common/components/CustomModal/AlertModal";
import routes from "../../../../common/routes";
import { resetUserError } from "../../../../store/users";
import {
  toggleUserPermission,
  toggleUserStatus,
} from "../../../../store/users/userAsyncThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

function UsersAction() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector((state) => state.users);
  const { state } = useLocation();
  const submitRef = useRef(false);
  const confirmActionHandler = () => {
    submitRef.current = true;
    dispatch(
      toggleUserStatus({
        user_id: state?._id,
        is_active: !state?.is_active,
      })
    );
  };

  const goBack = () =>
    navigate(routes.protected.instance.users, {
      replace: true,
      state: null,
    });

  useEffect(() => {
    if (loading === false && submitRef.current === true) {
      // dispatch(resetUserError());
      navigate(routes.protected.instance.users, {
        replace: true,
        state: null,
      });
    }
  }, [loading, dispatch, navigate]);

  return (
    <AlertModal
      backDropClick={goBack}
      leftAction={goBack}
      rightAction={confirmActionHandler}
      action_text={`${state?.is_active === true ? "Deactivate" : "Activate"}`}
    />
  );
}

export default UsersAction;
