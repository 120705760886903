import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { LocalDataType } from "types";
import { MerchantType } from "types/merchant-types";
import {
  approveMerchantAccount,
  getAllPendingMerchants,
} from "./pendingMerchantAsyncThunk";

const initialState: LocalDataType<MerchantType[]> = {
  data: [],
  error: "",
  loading: false,
  pagination: {
    currentPage: 1,
    limit: 20,
    totalItems: 1,
  },
};

const pendingMerchantSlice = createSlice({
  name: "pendingMerchants",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<{ page: number }>) {
      state.pagination.currentPage = action.payload.page;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getAllPendingMerchants.pending, (state) => {
      state.loading = true;
    });

    builders.addCase(getAllPendingMerchants.fulfilled, (state, action) => {
      state.data = action.payload?.data || [];
      state.pagination.totalItems = action.payload?.results || 1;
      state.loading = false;
      state.error = "";
    });

    builders.addCase(approveMerchantAccount.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item._id !== action.payload.id);
    });

    builders.addCase(getAllPendingMerchants.rejected, (state, action) => {
      state.loading = false;
      state.error =
        typeof action.payload === "string"
          ? action.payload
          : "Something went wrong";
    });

    builders.addMatcher(
      isAnyOf(getAllPendingMerchants.pending, approveMerchantAccount.pending),
      (state) => {
        state.loading = true;
      }
    );

    builders.addMatcher(
      isAnyOf(
        getAllPendingMerchants.fulfilled,
        getAllPendingMerchants.rejected,
        approveMerchantAccount.fulfilled,
        approveMerchantAccount.rejected
      ),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const { setCurrentPage } = pendingMerchantSlice.actions;

export default pendingMerchantSlice.reducer;
