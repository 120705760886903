import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import SideModal from "common/components/CustomModal/SideModal";
import CustomButtom from "common/components/CustomButton";
import { DashboardInput } from "common/components/DashboardComponents/Inputs/dashbaord-input";
import DashboardSelect from "common/components/DashboardComponents/Inputs/dashboard-select";
import service from "common/service/protected";
import { useAppDispatch } from "store/hooks";
import { getAllSavingsPlan } from "store/savings/savingsAsyncThunk";
import customToast from "common/components/CustomToast";
import { CreateSavingsStateType } from "types/form-state-types";
import { createSavingsSchema } from "common/form-schema/create-savings";

const AUTO_SAVE_OPTIONS = [
  {
    text: "Yes",
    value: true,
  },
  {
    text: "No",
    value: false,
  },
];

const WITHDRAWAL_INTERVAL = [
  {
    text: "Daily",
    value: "daily",
  },
  {
    text: "Weekly",
    value: "weekly",
  },
  {
    text: "Monthly",
    value: "monthly",
  },
  {
    text: "Quarterly",
    value: "Quarterly",
  },
];

const CreateSaving = () => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    reset,
    handleSubmit,
  } = useForm<CreateSavingsStateType>({
    resolver: yupResolver(createSavingsSchema),
    defaultValues: {
      auto_save: AUTO_SAVE_OPTIONS[0],
      interest_payout_intervals: WITHDRAWAL_INTERVAL[0],
      savings_intervals: WITHDRAWAL_INTERVAL[0],
      withdrawal_intervals: WITHDRAWAL_INTERVAL[0],
    },
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleCreateSavings: SubmitHandler<CreateSavingsStateType> = async (
    result
  ) => {
    const {
      auto_save,
      interest_payout_intervals,
      interest_rate,
      name,
      savings_intervals,
      withdrawal_intervals,
    } = result;

    const data = {
      name,
      auto_save: auto_save.value,
      strict_auto_save: false,
      interest_rate: +interest_rate,
      withdrawal_intervals: withdrawal_intervals.value,
      interest_payout_intervals: interest_payout_intervals.value,
      savings_intervals: [savings_intervals.value],
      is_active: false,
    };
    try {
      setLoading(true);
      const response: any = await service.createSavingsPlan(data);
      dispatch(getAllSavingsPlan({}));
      customToast(response?.message || "Savings plan created");
      reset();
    } catch (error: any) {
      customToast(error?.message || "Unknown Error", true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SideModal
      className="p-[4.25rem]"
      clickBackDrop={() => navigate(-1)}
      position={undefined}
    >
      <header className="pb-2 border-b border-[#ADADAD]">
        <h4 className="text-4xl font-medium text-base-300">Create Savings</h4>
      </header>
      <section className="pt-[18px] pb-12">
        <p className="text-base leading-5 text-gray-600 mb-[1.813rem]">
          Input the details to create a savings plan on this platform
        </p>
        <div className="">
          <form
            className="flex flex-col gap-y-5"
            onSubmit={handleSubmit(handleCreateSavings)}
          >
            <DashboardInput
              labelText="Savings name"
              register={register("name")}
              required
              error={errors.name}
            />
            <DashboardSelect
              labelText="Auto Save"
              options={AUTO_SAVE_OPTIONS}
              control={control}
              name="auto_save"
              required
              error={errors.auto_save}
            />
            <div className="flex justify-between">
              <div className="flex-[0.485] flex-shrink-0">
                <DashboardInput
                  labelText="Interest rate(%)"
                  type="number"
                  register={register("interest_rate", {
                    onChange(e) {
                      const { value } = e.target;
                      if (+value < 1) setValue("interest_rate", "1");
                      else if (+value > 100) setValue("interest_rate", "100");
                      else setValue("interest_rate", value);
                    },
                  })}
                  error={errors.interest_rate}
                />
              </div>
              <div className="flex-[0.485] flex-shrink-0">
                <DashboardSelect
                  labelText="Withdrawal interval"
                  options={WITHDRAWAL_INTERVAL}
                  control={control}
                  name="withdrawal_intervals"
                  error={errors.withdrawal_intervals}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex-[0.485] flex-shrink-0">
                <DashboardSelect
                  labelText="Interest payout interval"
                  options={WITHDRAWAL_INTERVAL}
                  control={control}
                  name="interest_payout_intervals"
                  error={errors.interest_payout_intervals}
                  required
                />
              </div>
              <div className="flex-[0.485] flex-shrink-0">
                <DashboardSelect
                  labelText="Savings interval"
                  options={WITHDRAWAL_INTERVAL}
                  control={control}
                  name="withdrawal_intervals"
                  error={errors.withdrawal_intervals}
                  required
                />
              </div>
            </div>
            <div className="mt-4">
              <CustomButtom className="w-[13.688rem]" disable={loading}>
                Submit
              </CustomButtom>
            </div>
          </form>
        </div>
      </section>
    </SideModal>
  );
};

export default CreateSaving;
