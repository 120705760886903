import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CustomButtom from "../../CustomButton";
import BackDrop from "./BackDrop";

function AlertModal({
  leftAction,
  rightAction,
  backDropClick,
  action_text,
}: any) {
  const [action, setAction] = useState("delete");
  const { state } = useLocation();
  useEffect(() => {
    if (state?.action_type && action !== state?.action_type) {
      setAction(state?.action_type);
    }
  }, [state?.action_type]); // eslint-disable-line

  return (
    <BackDrop handleClose={backDropClick}>
      <div className="text-center">
        <h2 className="mb-12 text-[2rem] leading-[2.4rem]">
          Are you sure you want to {action_text ? action_text : action}
        </h2>
        <div className="flex items-center justify-center gap-x-[2.625rem]">
          <CustomButtom action={leftAction} variant type="button">
            No
          </CustomButtom>
          <CustomButtom action={rightAction} type="button">
            Yes
          </CustomButtom>
        </div>
      </div>
    </BackDrop>
  );
}

export default AlertModal;
