import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SideModal from "common/components/CustomModal/SideModal";
import { DashboardInput } from "common/components/DashboardComponents/Inputs/dashbaord-input";
import DashboardSelect from "common/components/DashboardComponents/Inputs/dashboard-select";
import CustomButtom from "common/components/CustomButton";
import Dropzone from "common/components/CustomDragandDrop";
import { RiDeleteBin5Line } from "react-icons/ri";
import service from "common/service/protected";
import customToast from "common/components/CustomToast";
import { useAppDispatch } from "store/hooks";
import { getAllAssets } from "store/transactions/transactionsAsyncThunk";
import { CreateAssetFormStateType } from "types/form-state-types";
import { createAssetSchema } from "common/form-schema/create-asset";

const CATEGORY = [
  { text: "Fiat", value: "fiat" },
  { text: "Cryptocurrency", value: "crypto" },
];

const COUNTRY = [
  { text: "Nigeria", value: "Nigeria" },
  { text: "Ghana", value: "Ghana" },
  { text: "United States", value: "United States" },
  { text: "Saudi Arabia", value: "Saudi Arabia" },
];

const CURRENCY = [
  { text: "NGN", value: "NGN" },
  { text: "GHC", value: "GHC" },
  { text: "USD", value: "USD" },
  { text: "SRA", value: "SRA" },
];

const CreateAsset = () => {
  const navigate = useNavigate();

  const {
    setValue,
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<CreateAssetFormStateType>({
    resolver: yupResolver(createAssetSchema),
    defaultValues: {
      category: CATEGORY[0],
      country: COUNTRY[0],
      country_currency: CURRENCY[0],
    },
  });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleCreateAsset: SubmitHandler<CreateAssetFormStateType> = async (
    data
  ) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      let value = data[item as keyof CreateAssetFormStateType];
      if (typeof value === "object") value = value.value;
      if (value === "") return;
      formData.append(item, value as string);
    });
    formData.append("logo", selectedFiles[0]);

    try {
      setLoading(true);
      const response: any = await service.createAsset(formData);
      dispatch(getAllAssets({}));
      customToast(response.message);
      reset();
    } catch (error: any) {
      customToast(error.message, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SideModal
      className="p-[4.25rem]"
      clickBackDrop={() => navigate(-1)}
      position={undefined}
    >
      <header className="pb-2 border-b border-gray-700">
        <h4 className="text-4xl font-medium text-base-300">Create Asset</h4>
      </header>
      <section className="pt-[18px] pb-12">
        <p className="text-base leading-5 text-gray-600 mb-[1.813rem]">
          Input the details to create asset on this platform
        </p>
        <div className="">
          <form
            className="flex flex-col gap-y-5"
            onSubmit={handleSubmit(handleCreateAsset)}
          >
            <DashboardInput
              labelText="Name"
              register={register("name")}
              error={errors.name}
              required
            />
            <div className="flex justify-between">
              <div className="flex-[0.485] flex-shrink-0">
                <DashboardInput
                  labelText="Symbol"
                  required
                  register={register("crypto_symbol")}
                  error={errors.crypto_symbol}
                />
              </div>
              <div className="flex-[0.485] flex-shrink-0">
                <DashboardSelect
                  labelText="Category"
                  name="category"
                  required
                  control={control}
                  options={CATEGORY}
                  error={errors.category}
                />
              </div>
            </div>
            <DashboardInput
              labelText="Decimal Point"
              type="number"
              register={register("decimal_places", {
                onChange(event) {
                  if (Number(event.target.value) < 1) {
                    setValue("decimal_places", "1");
                    return;
                  }
                  setValue("decimal_places", event.target.value);
                },
              })}
              error={errors.decimal_places}
            />
            <div className="flex justify-between">
              <div className="flex-[0.485] flex-shrink-0">
                <DashboardSelect
                  labelText="Country"
                  required
                  name="country"
                  control={control}
                  options={COUNTRY}
                  error={errors.country}
                />
              </div>
              <div className="flex-[0.485] flex-shrink-0">
                <DashboardSelect
                  labelText="Currency"
                  name="country_currency"
                  control={control}
                  options={CURRENCY}
                  error={errors.country_currency}
                  required
                />
              </div>
            </div>
            <div className="flex justify-between items-end">
              <div className="w-[14.688rem] flex flex-col gap-y-2">
                <label
                  htmlFor="asset_logo"
                  className="text-[#090914] font-medium inline-block"
                >
                  Aseset Logo*
                </label>
                <div className="">
                  <Dropzone
                    accept={"image/*"}
                    maxFiles={1}
                    onDrop={(e) => {
                      setSelectedFiles(e);
                    }}
                    open={() => {}}
                    isNotVisible
                  />
                </div>
              </div>
              <div className="pb-3">
                {Boolean(selectedFiles.length) && (
                  <div className="mb-2">
                    <h4 className="text-[#676767]">Uploaded</h4>
                  </div>
                )}
                {selectedFiles.map((file, idx) => (
                  <div
                    key={idx}
                    className="px-3 py-2 border border-[#11AF22] flex justify-between rounded"
                  >
                    <p className="w-[80%] truncate text-sm text-[#0F0F0F]">
                      {file.name}
                    </p>
                    <button
                      onClick={() => setSelectedFiles([])}
                      className="bg-[#FFF3F3] text-[#E41D1D] rounded-full p-1"
                    >
                      <RiDeleteBin5Line />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-4">
              <CustomButtom
                disable={loading || !Boolean(selectedFiles.length)}
                className="w-[13.688rem]"
              >
                Submit
              </CustomButtom>
            </div>
          </form>
        </div>
      </section>
    </SideModal>
  );
};

export default CreateAsset;
