import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "common/components/CustomToast";
import { finswitchServices as service } from "common/service/protected";
import { RequestBodyType } from "types";

export const getAllMerchants = createAsyncThunk<any, RequestBodyType>(
  "merchants/getAllMerchants",
  async (data, { rejectWithValue }) => {
    return service
      .getMerchants(data, true)
      .then((res) => res)
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const updateMerchant = createAsyncThunk<
  any,
  { id: string; reason?: string; is_active: boolean }
>("merchants/updateMerchant", async (data, { rejectWithValue }) => {
  return service
    .updateMerchant(data.id, data)
    .then((res) => {
      customToast(res.message);

      return data;
    })
    .catch((error) => {
      customToast(error.message, true);
      return rejectWithValue(error.message);
    });
});
