import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "common/components/CustomToast";
import { finswitchServices as services } from "common/service/protected";
import { RequestBodyType } from "types";

export const retrieveMerchant = createAsyncThunk<any, { id: string }>(
  "merchantInfo/retrieveMerchant",
  async (data, { rejectWithValue }) => {
    return services
      .retrieveMerchant(data.id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const approveMerchant = createAsyncThunk<any, { id: string }>(
  "merchantInfo/approveMerchant",
  async (data, { rejectWithValue }) => {
    return services
      .approveMerchantAccount({ id: data.id })
      .then((res) => {
        customToast(res.message);
        return data;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const updateMerchantStatus = createAsyncThunk<
  any,
  {
    id: string;
    data: {
      reason?: string;
      is_active: boolean;
    };
  }
>("merchantInfo/updateMerchantStatus", async (data, { rejectWithValue }) => {
  return services
    .updateMerchant(data.id, data.data)
    .then((res) => {
      customToast(res.message);
      return data.data;
    })
    .catch((error) => {
      customToast(error.message, true);
      return rejectWithValue(error.message);
    });
});

export const getComplianceDocs = createAsyncThunk<any, { id: string }>(
  "merchantInfo/getComplianceDocs",
  async (data, { rejectWithValue }) => {
    return services
      .getComplicanceDocs(data.id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const updateComplianceDocs = createAsyncThunk<
  any,
  {
    id: string;
    data: {
      reason?: string;
      status: "REJECTED" | "APPROVED";
    };
  }
>("merchantInfo/updateComplianceDocs", async (data, { rejectWithValue }) => {
  return services
    .updateComplianceDoc(data.id, data.data)
    .then((res) => {
      customToast(res.message);
      return res;
    })
    .catch((error) => {
      customToast(error.message, true);
      return rejectWithValue(error.message);
    });
});

export const getComplianceStakeHolders = createAsyncThunk<
  any,
  {
    id: string;
  }
>(
  "merchantInfo/getComplianceStakeHolders",
  async (data, { rejectWithValue }) => {
    return services
      .getComplianceStakeHolders(data.id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const getAllMerchantApps = createAsyncThunk<any, RequestBodyType>(
  "merchantInfo/getAllMerchantApps",
  async (data, { rejectWithValue }) => {
    return services
      .getAllApps(data)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const getAllPolicy = createAsyncThunk<any, { appId: string }>(
  "merchantInfo/getAllPolicy",
  async (data, { rejectWithValue }) => {
    return services
      .getAllAppPolicy(data.appId)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const updatePolicy = createAsyncThunk<
  any,
  { appId: string; policyId: string; data: { is_active: boolean } }
>(
  "merchantInfo/updatePolicy",
  async ({ appId, policyId, data }, { rejectWithValue }) => {
    return services
      .updatePolicy(appId, policyId, data)
      .then((res) => {
        customToast(res.message);
        return { data, policyId };
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);
