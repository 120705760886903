import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../common/components/CustomToast";
import service from "../../common/service/protected";
import { RequestBodyType } from "types";

export const getAllUsers = createAsyncThunk<any, RequestBodyType>(
  "users/getAllUsers",
  async (data, { rejectWithValue }) => {
    return service
      .retriveAllUsers(data)
      .then((res) => res)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err.message);
      });
  }
);
export const toggleUserPermission = createAsyncThunk<
  any,
  { [key: string]: any }
>("users/toggleUserPermission", async (data, { rejectWithValue }) => {
  return service
    .toggleUserPermission(data)
    .then((res) => res.data)
    .catch((err) => {
      customToast(err.message, true);
      return rejectWithValue(err.message);
    });
});

export const toggleUserStatus = createAsyncThunk<
  any,
  { is_active: boolean; user_id: string }
>("users/toggleUserStatus", async (data, { rejectWithValue }) => {
  return service
    .toggleUserStatus(data)
    .then((res) => res.data)
    .catch((err) => {
      customToast(err.message, true);
      return rejectWithValue(err.message);
    });
});
