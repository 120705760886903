import React, { useEffect } from "react";

interface SideModalProps {
  children: React.ReactNode;
  clickBackDrop(): void;
  position: string | null | undefined;
  className: string;
}

function SideModal({
  children,
  clickBackDrop,
  position,
  className,
}: SideModalProps) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div
      onClick={clickBackDrop}
      className=" fixed top-0 left-0 h-full overflow-y-auto z-50 bg-base-4 w-full "
    >
      <div
        className={`flex items-start ${
          position ? "justify-start" : "justify-end"
        } h-full w-full`}
      >
        <section
          onClick={(e) => e.stopPropagation()}
          className={`bg-white min-h-screen  w-1/2 ${className}`}
        >
          {children}
        </section>
      </div>
    </div>
  );
}

export default SideModal;
