import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { LocalDataType } from "types";
import { ExchangerUser } from "types/exchanger";
import {
  getExchangers,
  searchExchangers,
  blockExchanger,
  unblockExchanger,
  blockExchangerFunding,
  blockExchangerWithdrawal,
  unblockExchangerFunding,
  unblockExchangerWithdrawal,
} from "./exchangersAsyncThunk";

const initialState: LocalDataType<ExchangerUser[]> = {
  error: "",
  loading: false,
  data: [],
  pagination: {
    currentPage: 1,
    limit: 10,
    totalItems: 1,
  },
};

const exchangersSlice = createSlice({
  name: "exchangers",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<{ page: number }>) {
      state.pagination.currentPage = action.payload.page;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getExchangers.fulfilled, (state, action) => {
      state.data = action.payload?.data?.list || [];
      state.pagination.totalItems = action.payload?.data?.total || 1;
    });

    builders.addCase(searchExchangers.fulfilled, (state, action) => {
      state.data = action.payload?.data || [];
      state.pagination = initialState.pagination;
    });

    builders.addMatcher(
      isAnyOf(
        getExchangers.rejected,
        getExchangers.fulfilled,
        searchExchangers.rejected,
        searchExchangers.fulfilled,
        blockExchanger.rejected,
        blockExchanger.fulfilled,
        unblockExchanger.rejected,
        unblockExchanger.fulfilled,
        blockExchangerFunding.fulfilled,
        blockExchangerFunding.rejected,
        blockExchangerWithdrawal.fulfilled,
        blockExchangerWithdrawal.rejected,
        unblockExchangerFunding.rejected,
        unblockExchangerFunding.fulfilled,
        unblockExchangerWithdrawal.rejected,
        unblockExchangerWithdrawal.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );

    builders.addMatcher(
      isAnyOf(
        getExchangers.pending,
        searchExchangers.pending,
        unblockExchanger.pending,
        blockExchanger.pending,
        blockExchangerFunding.pending,
        blockExchangerWithdrawal.pending,
        unblockExchangerFunding.pending,
        unblockExchangerWithdrawal.pending
      ),
      (state) => {
        state.loading = true;
      }
    );
  },
});

export const { setCurrentPage } = exchangersSlice.actions;

export default exchangersSlice.reducer;
