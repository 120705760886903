import { Route, Routes } from "react-router-dom";
import CreateAdmin from "./CreateAdmin";
import routes from "common/routes";
import CreateStory from "./CreateStory";
import StoryAction from "./StoryAction";
import UsersAction from "./UserAction";
import CreateAsset from "./CreateAsset";
import CreateBankFormat from "./CreateBankFormat";
import CreateSaving from "./CreateSaving";
import PasswordAction from "./PasswordAction";

const modalRoutes = [
  {
    id: 1,
    component: CreateAdmin,
    path: routes.protected.instance.create_admin,
  },
  {
    id: 2,
    component: CreateStory,
    path: routes.protected.instance.create_story_update,
  },
  {
    id: 3,
    component: StoryAction,
    path: routes.protected.instance.story_update_action,
  },
  {
    id: 4,
    component: UsersAction,
    path: routes.protected.instance.users_action,
  },
  {
    id: 5,
    component: CreateAsset,
    path: routes.protected.instance.create_asset,
  },
  {
    id: 6,
    component: CreateBankFormat,
    path: routes.protected.instance.create_bank_format,
  },
  {
    id: 7,
    component: CreateSaving,
    path: routes.protected.instance.create_saving,
  },
  {
    id: 8,
    component: PasswordAction,
    path: routes.protected.instance.settings_action,
  },
];

export const InstanceModals = () => {
  return (
    <Routes>
      {modalRoutes.map(({ component: Component, id, path }) => (
        <Route element={<Component />} key={id} path={path} />
      ))}
    </Routes>
  );
};
