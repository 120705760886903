import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import { TiArrowLeft } from "react-icons/ti";
import cuid from "cuid";
import MediaUpload from "../CreateStoryForms/MediaUpload";
import TextUpload from "../CreateStoryForms/TextUpload";
import { createStory } from "../../../../store/stories/storiesAsyncThunk";
import StoryTitle from "../CreateStoryForms/StoryTitle";
import { resetStage } from "../../../../store/stories";
import routes from "../../../../common/routes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

const initialstate = {
  title: "",
  media: [],
  texts: [],
  start_date: new Date().toISOString(),
  time: "0:00",
  duration_in_mins: "10",
};

function CreateStory() {
  const navigate = useNavigate();
  const [tab, setTab] = useState("media");
  const [state, setState] = useState(initialstate);
  const dispatch = useAppDispatch();
  const submitRef = useRef(false);
  const loading = useAppSelector((state) => state?.stories?.loading);
  const stage = useAppSelector((state) => state?.stories?.createStory?.stage);

  const changeHandler = (e: any) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onBackDropClick = () => {
    navigate(-1);
    dispatch(resetStage());
  };

  const toInitialStage = () => {
    dispatch(resetStage());
  };

  const dateChange = (date: any) => {
    setState((prev) => ({
      ...prev,
      start_date: date.toISOString(),
    }));
  };

  const switchTabsHandler = (e: any) => {
    const { name } = e.target;
    setTab(name);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setState((prev: any) => ({
          ...prev,
          media: [...prev.media, { id: cuid(), src: file, name: file.name }],
        }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const onRemoveMedia = (id: any) => {
    const temp = state.media.filter((img: any) => img.id !== id);
    setState((prev) => ({ ...prev, media: [...temp] }));
  };

  useEffect(() => {
    if (tab === "text" && state.media.length > 0) {
      setState((prev) => ({ ...prev, media: [] }));
    }
    if (tab === "media" && state.media.length > 0) {
      setState((prev) => ({ ...prev, text_upload: "" }));
    }
  }, [tab]); //eslint-disable-line

  useEffect(() => {
    if (loading === false && submitRef.current === true) {
      navigate(routes.protected.instance.story_update);
    }
  }, [loading]); // eslint-disable-line

  const submitHandler = (e: any) => {
    dispatch(createStory(state));
    submitRef.current = true;
  };

  return (
    <SideModal
      className="px-20 py-16 relative"
      clickBackDrop={onBackDropClick}
      position={null}
    >
      {stage === "" ? (
        <StoryTitle
          changeHandler={changeHandler}
          dateChange={dateChange}
          state={state}
        />
      ) : null}
      {stage === "content" ? (
        <>
          <header className="pb-7   flex flex-col justify-end bg-white ">
            <nav className="pb-12">
              <button
                onClick={toInitialStage}
                className="flex items-center gap-x-3"
              >
                <TiArrowLeft size={32} />
                <span>Back to Story title</span>
              </button>
            </nav>
            <h4 className="text-4xl leading-[2.625rem] font-semibold text-base-300 border-b border-gray-700 pb-3.5">
              Upload Content
            </h4>
            <p className="pt-3.5 text-sm leading-4.5 text-gray-750">
              Choose the kind of content you want to upload by switching tab
            </p>
          </header>
          <div className="flex items-center border-b gap-x-9 text-xl leading-6">
            <button
              onClick={switchTabsHandler}
              name="media"
              className={`pb-2 border-b-4 ${
                tab === "media"
                  ? "border-base-200 font-semibold text-base-200"
                  : "border-transparent font-medium text-gray-300"
              }`}
            >
              Video & image Upload
            </button>
            <button
              onClick={switchTabsHandler}
              name="text"
              className={`pb-2 border-b-4 ${
                tab === "text"
                  ? "border-base-200 font-semibold text-base-200"
                  : "border-transparent font-medium text-gray-300"
              }`}
            >
              Text Story Upload
            </button>
          </div>

          {tab === "media" ? (
            <MediaUpload
              images={state.media}
              onDrop={onDrop}
              onSubmit={submitHandler}
              onRemoveMedia={onRemoveMedia}
            />
          ) : null}
          {tab === "text" ? (
            <TextUpload
              setState={setState}
              state={state}
              onSubmit={submitHandler}
            />
          ) : null}
        </>
      ) : null}
      {stage === "final" ? (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-col ">
            <h2 className="text-[40px] leading-[3rem] font-bold text-center">
              Story Published Successfully
            </h2>
            <button
              onClick={() => navigate(-1)}
              className="py-3 px-24 rounded-md text-white bg-blue-100 text-base mt-11 self-center"
            >
              close
            </button>
          </div>
        </div>
      ) : null}
    </SideModal>
  );
}

export default CreateStory;
