import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AlertModal from "../../../../common/components/CustomModal/AlertModal";
import routes from "../../../../common/routes";
import { deleteStory } from "../../../../store/stories/storiesAsyncThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

function StoryAction() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const submitRef = useRef(false);
  const loading = useAppSelector((state) => state.stories?.loading);
  const dispatch = useAppDispatch();

  const deleteHandler = () => {
    dispatch(deleteStory(state._id));
    submitRef.current = true;
  };
  const goBack = () => navigate(-1);

  const backDropHandler = useCallback(() => {
    if (!loading) {
      goBack();
    }
  }, [loading]); // eslint-disable-line

  useEffect(() => {
    if (loading === false && submitRef.current === true) {
      navigate(routes.protected.instance.story_update, {
        replace: true,
      });
    }
  }, [loading, submitRef.current]); // eslint-disable-line

  return (
    <AlertModal
      backDropClick={backDropHandler}
      leftAction={goBack}
      rightAction={deleteHandler}
      action_text=""
    />
  );
}

export default StoryAction;
