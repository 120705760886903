import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LocalDataType } from "types";
import { IndustryType } from "types/industry-types";
import { getAllIndustries } from "./industriesAsyncThunk";

const initialState: LocalDataType<IndustryType[]> = {
  data: [],
  error: "",
  loading: false,
  pagination: {
    currentPage: 1,
    limit: 20,
    totalItems: 1,
  },
};

export const industriesSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    updateIndustry(state, action: PayloadAction<{ id: string }>) {
      state.data = state.data.filter((item) => item._id !== action.payload.id);
    },
    setCurrentPage(state, action: PayloadAction<{ page: number }>) {
      state.pagination.currentPage = action.payload.page;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getAllIndustries.pending, (state) => {
      state.loading = true;
    });

    builders.addCase(getAllIndustries.fulfilled, (state, action) => {
      state.data = action.payload?.data || [];
      state.pagination.totalItems = action.payload?.results || 1;
      state.loading = false;
      state.error = "";
    });

    builders.addCase(getAllIndustries.rejected, (state, action) => {
      state.loading = false;
      state.error =
        typeof action.payload === "string"
          ? action.payload
          : "Something went wrong";
    });
  },
});

export const { updateIndustry, setCurrentPage } = industriesSlice.actions;

export default industriesSlice.reducer;
