import { NavLink, useNavigate } from "react-router-dom";
import { dashBoardLinks } from "../../../utils/helpers/helper";
import { IoLogOut } from "react-icons/io5";
import { clearUserDetails } from "../../../service/storage";
import routes from "../../../routes";
import { logoutAdmin } from "../../../../store/auth";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { HeadlessDropDown } from "../../CustomDropdown/headless-dropdown";
import { CurrentDashboardType } from "../../../../types";
import { changeDashboard } from "../../../../store/dashboard";
import styles from "static/styles/scrollbar.module.css";

interface SideNavProps {
  className?: string;
}

function SideNav({ className }: SideNavProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logoutHandler = () => {
    clearUserDetails();
    dispatch(logoutAdmin());
    navigate(routes.nonprotected.login);
  };

  const { currentDashboard } = useAppSelector((state) => state.dashboard);

  const handleDashboardChange = (value: CurrentDashboardType) => {
    dispatch(changeDashboard({ dashboard: value }));
    navigate(routes.protected[value].dashboard);
  };

  return (
    <nav
      className={`${
        className ? className : ""
      } flex flex-col gap-y-5  py-8 w-72 ${styles.no_scrollbar}`}
    >
      <div className="flex justify-center mt-4 mb-6">
        <HeadlessDropDown
          selected={currentDashboard}
          handleChange={handleDashboardChange}
        />
      </div>
      <div className="row-start-3 row-end-4 flex flex-col gap-y-1 px-10">
        {dashBoardLinks[currentDashboard].map((itm, index) => (
          <NavLink
            key={index}
            to={itm.link}
            className={({ isActive }) =>
              isActive
                ? "text-white bg-blue-100 flex items-center gap-x-6 pt-4 pb-5 pl-7 text-base leading-5 rounded-2xl relative before:content-[' '] before:absolute before:w-3 before:h-full before:bg-blue-100 before:left-[-2.5rem] before:top-0 before:bottom-0 before:rounded-tr-2xl before:rounded-br-2xl"
                : " text-gray-300 flex items-center gap-x-6 pt-4 pb-5 pl-7 text-base leading-5"
            }
          >
            {itm.icon}
            <span>{itm.title}</span>
          </NavLink>
        ))}
      </div>
      <button
        onClick={logoutHandler}
        className="row-start-5 row-end-6 flex items-center gap-x-6  pt-4 pb-5 pl-7 mx-10 text-error-100"
      >
        <IoLogOut />
        <span>Logout</span>
      </button>
    </nav>
  );
}

export default SideNav;
