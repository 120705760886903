import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "common/components/CustomToast";
import { finswitchServices as service } from "common/service/protected";
import { RequestBodyType } from "types";

export const getAllApps = createAsyncThunk<any, RequestBodyType>(
  "applications/getAllApps",
  async (data, { rejectWithValue }) => {
    return service
      .getAllApps(data)
      .then((res) => res)
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const deleteApp = createAsyncThunk<any, { id: string }>(
  "applications/deleteApp",
  async (data, { rejectWithValue }) => {
    return service
      .deleteApp(data.id)
      .then((res) => {
        customToast(res.message);
        return data.id;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const updateApp = createAsyncThunk<
  any,
  { id: string; reason?: string; is_active: boolean }
>("applications/updateApp", async (data, { rejectWithValue }) => {
  return service
    .updateApp(data.id, data)
    .then((res) => {
      customToast(res.message);

      return data;
    })
    .catch((error) => {
      customToast(error.message, true);
      return rejectWithValue(error.message);
    });
});
