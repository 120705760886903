import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createStory, deleteStory, getAllStories } from "./storiesAsyncThunk";
import { StoriesType } from "../../types";

const initialState: StoriesType = {
  loading: false,
  createStory: {
    stage: "",
  },
  stories: [],
};

export const storiesSlice = createSlice({
  name: "stories",
  initialState,
  reducers: {
    changeStage: (state, action) => {
      state.createStory.stage = action.payload;
    },
    resetStage: (state) => {
      state.createStory.stage = initialState.createStory.stage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStory.fulfilled, (state) => {
        state.createStory.stage = initialState.createStory.stage;
        state.loading = initialState.loading;
      })
      .addCase(createStory.rejected, (state) => {
        state.createStory.stage = initialState.createStory.stage;
      })
      .addCase(getAllStories.fulfilled, (state, action) => {
        state.loading = initialState.loading;
        state.stories = action.payload;
      })
      .addCase(deleteStory.fulfilled, (state, action) => {
        state.loading = initialState.loading;
        state.stories = state.stories.filter(
          (story) => story._id !== action.payload._id
        );
      })

      .addMatcher(
        isAnyOf(
          createStory.rejected,
          getAllStories.rejected,
          deleteStory.rejected
        ),
        (state) => {
          state.loading = initialState.loading;
        }
      )
      .addMatcher(
        isAnyOf(
          createStory.pending,
          getAllStories.pending,
          deleteStory.pending
        ),
        (state) => {
          state.loading = true;
        }
      );
  },
});

export const { changeStage, resetStage } = storiesSlice.actions;

export default storiesSlice.reducer;
