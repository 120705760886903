import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  authenticatorGetStarted,
  enableGoogleAuthenticator,
} from "./authenticatorAsyncThunk";

const initialState = {
  currentStep: 1,
  authenticatorCode: "",
  loading: false,
  isVerificationSuccess: false,
  data: {
    qrImage: "",
    recoveryKeys: [],
  },
  error: "",
};

const authenticatorSlice = createSlice({
  name: "authenticator",
  initialState,
  reducers: {
    setCurrentStep(state, action: PayloadAction<{ step: number }>) {
      state.currentStep = action.payload.step;
    },
    setAuthenticatorCode(state, action: PayloadAction<{ code: string }>) {
      state.authenticatorCode = action.payload.code;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(authenticatorGetStarted.fulfilled, (state, action) => {
      state.data.qrImage = action.payload?.dataUrl || "";
      state.loading = false;
    });

    builders.addCase(enableGoogleAuthenticator.fulfilled, (state, action) => {
      state.data.recoveryKeys = action.payload?.recovery_keys || [];
      state.loading = false;
      state.isVerificationSuccess = true;
    });

    builders.addMatcher(
      isAnyOf(
        authenticatorGetStarted.pending,
        enableGoogleAuthenticator.pending
      ),
      (state) => {
        state.loading = true;
      }
    );

    builders.addMatcher(
      isAnyOf(
        authenticatorGetStarted.rejected,
        enableGoogleAuthenticator.rejected
      ),
      (state, action) => {
        if (typeof action.payload === "string") state.error = action.payload;
        state.loading = false;
        state.isVerificationSuccess = false;
      }
    );
  },
});

export const { setCurrentStep, setAuthenticatorCode } =
  authenticatorSlice.actions;
export default authenticatorSlice.reducer;
