import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

interface DropzoneProps {
  open?(): void;
  onDrop?(files: File[]): void;
  accept: any;
  isNotVisible?: boolean;
  maxFiles?: number;
}

function Dropzone({
  onDrop,
  accept,
  open,
  isNotVisible,
  maxFiles,
}: DropzoneProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    maxFiles,
  });

  return (
    <div className="">
      <div
        {...getRootProps({
          className:
            "p-9 bg-[#F8F8FF] border border-dashed border-purple-500 rounded",
        })}
      >
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <div>
              <FiUploadCloud
                className="mx-auto mb-6  text-purple-450"
                size={60}
              />
              <div className="flex flex-col gap-y-2.5">
                <div className="flex items-center justify-center gap-x-1 text-base font-bold">
                  <p className="text-black text-xs">Drag & drop files or </p>
                  <button
                    type="button"
                    onClick={open}
                    className="text-purple-450 border-b text-xs border-purple-450 leading-none"
                  >
                    Browse
                  </button>
                </div>
                {!isNotVisible && (
                  <p className="text-gray-250 text-xs">
                    Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word,
                    PPT
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dropzone;
