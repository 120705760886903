import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SavingsStateType } from "types";
import { getAllSavingsPlan, getSavingsTransactions } from "./savingsAsyncThunk";

const initialState: SavingsStateType = {
  savingsPlan: {
    data: [],
    error: "",
    loading: false,
    pagination: {
      currentPage: 1,
      limit: 20,
      totalItems: 1,
    },
  },
  transactions: {
    data: [],
    error: "",
    loading: false,
    pagination: {
      currentPage: 1,
      limit: 20,
      totalItems: 1,
    },
  },
};

export const savingsSlice = createSlice({
  name: "savings",
  initialState,
  reducers: {
    setCurrentPage(
      state,
      action: PayloadAction<{
        pageNumber: number;
        desc: "savingsPlan" | "transactions";
      }>
    ) {
      state[action.payload.desc].pagination.currentPage =
        action.payload.pageNumber;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getAllSavingsPlan.pending, (state) => {
      state.savingsPlan.loading = true;
    });

    builders.addCase(getSavingsTransactions.pending, (state) => {
      state.transactions.loading = true;
    });

    builders.addCase(getAllSavingsPlan.fulfilled, (state, action) => {
      state.savingsPlan.data = action.payload?.data || [];
      state.savingsPlan.pagination.totalItems = action.payload?.results || 1;
      state.savingsPlan.loading = false;
      state.savingsPlan.error = "";
    });

    builders.addCase(getSavingsTransactions.fulfilled, (state, action) => {
      state.transactions.data = action.payload?.data || [];
      state.transactions.pagination.totalItems = action.payload?.results || 1;
      state.transactions.loading = false;
      state.transactions.error = "";
    });

    builders.addCase(getAllSavingsPlan.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.savingsPlan.error = action.payload;
      }
      state.savingsPlan.loading = false;
    });

    builders.addCase(getSavingsTransactions.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.transactions.error = action.payload;
      }
      state.transactions.loading = false;
    });
  },
});

export const { setCurrentPage } = savingsSlice.actions;

export default savingsSlice.reducer;
