import instance from "../../../../static/images/instancelogo.svg";
import { Navigate, Outlet } from "react-router-dom";
import { fetchUserToken } from "../../../service/storage";
import routes from "../../../routes";
import { useAppSelector } from "../../../../store/hooks";

function Auth() {
  const { account_verified, verification_required } = useAppSelector(
    (state) => state.auth.admin
  );
  const { currentDashboard } = useAppSelector((state) => state.dashboard);
  if (fetchUserToken() && account_verified === true && !verification_required) {
    return (
      <Navigate replace to={routes.protected[currentDashboard].dashboard} />
    );
  }
  return (
    <div className="flex h-screen lg:flex-row w-full">
      <div className="bg-authBg bg-blue-100 bg-no-repeat bg-authPos bg-auto  py-[110px] px-4 sm:px-16 lg:px-24 w-1/2">
        <img src={instance} alt="instance logo" />
        <h1 className="text-6xl leading-[75px] font-semibold text-white mt-36 ">
          Welcome to the backoffice
        </h1>
      </div>
      <main className="w-1/2">
        <Outlet />
      </main>
    </div>
  );
}

export default Auth;
