import { UseFormRegisterReturn, FieldError } from "react-hook-form";

type props = {
  labelText: string;
  register: UseFormRegisterReturn<string>;
  error?: FieldError | undefined;
  type?: string;
  autoComplete?: string;
  required?: boolean;
};

export const DashboardInput = ({
  labelText,
  type,
  autoComplete,
  error,
  register,
  required,
}: props) => {
  let input: JSX.Element;

  switch (type) {
    case "textarea":
      input = (
        <textarea
          autoComplete={autoComplete}
          rows={6}
          {...register}
          className="bg-[#F8FAFC] outline-none focus:ring-2 focus:ring-[background: #293B96;
] focus:ring-offset-0 hover:ring-opacity-30 transition duration-150 border border-[#CBD5E1] rounded-xl w-full  flex-shrink-0 p-3"
        ></textarea>
      );
      break;

    default:
      input = (
        <input
          type={type || "text"}
          {...register}
          autoComplete={autoComplete}
          className="bg-[#F8FAFC] outline-none focus:ring-2 focus:ring-[background: #293B96;
] focus:ring-offset-0 hover:ring-opacity-30 transition duration-150 border border-[#CBD5E1] rounded-xl w-full h-[3rem] flex-shrink-0 px-3"
        />
      );
  }

  return (
    <div className="flex gap-y-2 flex-shrink-0 flex-col">
      <label className="text-[#090914] font-medium inline-block">
        {labelText}
        {required && "*"}
      </label>
      {input}
      <span className="  h-2 block lowercase text-xs text-red-500">
        {error && error.message}
      </span>
    </div>
  );
};
