import { Route, Routes } from "react-router-dom";
import routes from "common/routes";
import CreateIndustry from "./CreateIndustry";
import UpdateAppStatus from "./UpdateAppStatus";
import UpdateComplianceDocs from "./UpdateComplianceDocs";
import UpdateMerchantStatus from "./UpdateMerchantStatus";

const modalRoutes = [
  {
    id: 1,
    component: CreateIndustry,
    path: routes.protected.finswitch.create_industry,
  },
  {
    id: 2,
    component: UpdateAppStatus,
    path: routes.protected.finswitch.update_app,
  },
  {
    id: 3,
    component: UpdateComplianceDocs,
    path: routes.protected.finswitch.update_compliance_doc,
  },
  {
    id: 4,
    component: UpdateMerchantStatus,
    path: routes.protected.finswitch.update_merchant,
  },
];

export const FinswitchModals = () => {
  return (
    <Routes>
      {modalRoutes.map(({ component: Component, id, path }) => (
        <Route element={<Component />} key={id} path={path} />
      ))}
    </Routes>
  );
};
