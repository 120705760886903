import React, { useId } from "react";
import { useDispatch } from "react-redux";
import CustomDate from "../../../../common/components/CustomDate";
import Duration from "../../../../common/components/CustomSelect/story/Duration";
import Time from "../../../../common/components/CustomSelect/story/Time";
import { changeStage } from "../../../../store/stories";

function StoryTitle({ changeHandler, state, dateChange }: any) {
  const id = useId();
  const dispatch = useDispatch();

  const stageChangeHandler = () => {
    dispatch(changeStage("content"));
  };

  return (
    <>
      <header className="pb-2 border-b border-gray-700  flex flex-col justify-end bg-white ">
        <h4 className="text-4xl leading-[2.625rem] font-medium text-base-300">
          Create Story Update
        </h4>
      </header>
      <form
        className="pt-8 flex flex-col gap-y-10"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="">
          <label
            className="text-sm leading-none text-base-50"
            htmlFor={`${id}-title`}
          >
            Story Title
          </label>
          <input
            className="mt-2.5 w-full border border-gray-800 rounded py-5 px-2"
            name="title"
            type="text"
            id={`${id}-title`}
            value={state.title}
            onChange={changeHandler}
          />
          <p className="mt-2.5 text-[0.825rem] leading-[1.65rem] text-base-50">
            This will not show on the user side
          </p>
        </div>
        <div className="">
          <div>
            <label
              className="text-sm leading-none text-base-50"
              htmlFor={`${id}-date`}
            >
              Date and duration
            </label>
            <CustomDate
              minDate
              startDate={new Date(state.start_date)}
              onChange={dateChange}
              placeholder="Enter a date"
              type="picker"
            />
          </div>
          <div className="flex items-center gap-x-3 mt-5">
            <Time value={state.time} onChange={changeHandler} />
            <Duration value={state.duration_in_mins} onChange={changeHandler} />
          </div>
        </div>
        <button
          disabled={state.title.length < 6}
          type="button"
          onClick={stageChangeHandler}
          className="px-5 py-3 rounded-md text-white bg-blue-100 text-base mt-11 disabled:bg-slate-400 self-start"
        >
          Continue to upload content
        </button>
      </form>
    </>
  );
}

export default StoryTitle;
