import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./common/components/Layout";
import routes from "./common/routes";
import { generateRoute } from "./common/routes/generateRoute";
import route from "./common/routes/route";
import { getUserDashboardPreference } from "common/service/storage";
import { AppModals } from "common/components/Layout/Modals";

function App() {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  return (
    <>
      <Routes location={previousLocation || location}>
        <Route element={<Layout />}>
          {route.map((component) => generateRoute(component))}
        </Route>
        <Route
          path="*"
          element={
            <Navigate
              to={routes.protected[getUserDashboardPreference()].dashboard}
            />
          }
        />
      </Routes>
      {previousLocation && <AppModals />}
    </>
  );
}

export default App;
