import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../common/components/CustomToast";
import auth from "../../common/service/auth";
import service from "../../common/service/protected";
import { RequestBodyType } from "types";

export const createAdmin = createAsyncThunk<any, any>(
  "admins/createAdmin",
  async (data, { rejectWithValue }) => {
    return auth
      .registerAdmin(data)
      .then((res: any) => customToast(res.message))
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err.message);
      });
  }
);
export const getAllAdmins = createAsyncThunk<any, RequestBodyType>(
  "admins/getAllAdmins",
  async (data, { rejectWithValue }) => {
    return service
      .retriveAllAdmins(data)
      .then((res) => res)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err.message);
      });
  }
);
export const deactivateAdmin = createAsyncThunk<any, any>(
  "admins/deactivateAdmin",
  async (data) => {
    return service
      .deactivateAnAdmin(data)
      .then((res) => res.data)
      .catch((err) => customToast(err.message, true));
  }
);
