export const BASE_URL = process.env.REACT_APP_INSTANCEMVPADMIN;

export const REQUEST_TIMEOUT = 60000;
export const PAGE_SIZE = 10;

export const STORAGE_KEYS: { [key: string]: string } = {
  CLIENT_TOKEN_STORAGE_KEY: "instance.admin.mvp.token",
  CLIENT_TOKEN_REFRESH_KEY: "instance.admin.mvp.refresh_token",
  CLIENT_DASHBOARD_PREFERENCE_KEY: "instance.admin.mvp.dashboard_preference",
};
