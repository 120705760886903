import { useForm, SubmitHandler } from "react-hook-form";
import CustomButtom from "common/components/CustomButton";
import SideModal from "common/components/CustomModal/SideModal";
import { DashboardInput } from "common/components/DashboardComponents/Inputs/dashbaord-input";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getAllApps,
  updateApp,
} from "store/applications/applicationsAsyncThunk";

const UpdateAppStatus = () => {
  const {
    pagination: { limit, currentPage },
  } = useAppSelector((state) => state.applications);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm<{ reason: string }>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const handleAppStatus: SubmitHandler<{ reason: string }> = ({ reason }) => {
    if (!id) return;
    if (Boolean(state?.is_active) !== true) return;
    setLoading(true);
    dispatch(
      updateApp({ id, is_active: !Boolean(state?.is_active), reason })
    ).then(() => {
      setLoading(false);
      reset();
      dispatch(getAllApps({ limit, page: currentPage }));
    });
  };

  return (
    <SideModal
      className="p-[4.25rem]"
      clickBackDrop={() => navigate(-1)}
      position={undefined}
    >
      <header className="pb-2 border-b border-gray-700">
        <h4 className="text-4xl font-medium text-base-300">
          Update App Status
        </h4>
      </header>
      <section className="pt-[18px] pb-12">
        <p className="text-base leading-5 text-gray-600 mb-[1.813rem]">
          Input the details to update app status
        </p>
        <div className="">
          <form
            className="flex flex-col gap-y-5"
            onSubmit={handleSubmit(handleAppStatus)}
          >
            <DashboardInput
              type="textarea"
              labelText="Reason For Deactivation"
              register={register("reason", { required: true })}
            />
            <div className="mt-4">
              <CustomButtom className="w-[13.688rem]" disable={loading}>
                Submit
              </CustomButtom>
            </div>
          </form>
        </div>
      </section>
    </SideModal>
  );
};

export default UpdateAppStatus;
