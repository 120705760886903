import { lazy } from "react";
import routes from ".";

const loadModules = (link: string) =>
  lazy(() => import(`../../module/${link}`));

const route = [
  {
    path: routes.nonprotected.login,
    Component: loadModules("auth/pages/Login"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.two_fa,
    Component: loadModules("auth/pages/TwoFA"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.resetpassword,
    Component: loadModules("auth/pages/RestPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.forgotpassword,
    Component: loadModules("auth/pages/ForgotPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.enter_otp,
    Component: loadModules("auth/pages/EnterOtp"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.new_password,
    Component: loadModules("auth/pages/NewPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.reset_successful,
    Component: loadModules("auth/pages/ResetSuccessful"),
    access: "guest-only",
  },
  {
    path: routes.protected.instance.dashboard,
    Component: loadModules("instance/pages/Dashboard"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.dispute_and_issues,
    Component: loadModules("instance/pages/Dashboard"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.admin,
    Component: loadModules("instance/pages/Admin"),
    access: "loggedin-user",
    isModal: false,
  },

  {
    path: routes.protected.instance.story_update,
    Component: loadModules("instance/pages/Story"),
    access: "loggedin-user",
    isModal: false,
  },

  {
    path: routes.protected.instance.analytics_story_update,
    Component: loadModules("instance/pages/Story/AnalyticsStory"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.users,
    Component: loadModules("instance/pages/Users"),
    access: "loggedin-user",
    isModal: false,
  },

  {
    path: routes.protected.instance.transactions,
    Component: loadModules("instance/pages/Transactions"),
    access: "loggedin-user",
    isModal: false,
  },

  {
    path: routes.protected.instance.view_asset,
    Component: loadModules("instance/pages/Transactions/ViewAsset"),
    access: "loggedin-user",
    isModal: false,
  },

  {
    path: routes.protected.instance.savings,
    Component: loadModules("instance/pages/Savings"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.savings_transactions,
    Component: loadModules("instance/pages/Savings/SavingsTransaction"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.settings,
    Component: loadModules("instance/pages/Settings"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.general_settings,
    Component: loadModules("instance/pages/Settings/GeneralSettings"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.withdrawal_and_funding_settings,
    Component: loadModules("instance/pages/Settings/WithdrawalFundingSettings"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.funding_and_withdrawal_limit_settings,
    Component: loadModules(
      "instance/pages/Settings/WithdrawalFundingLimitSettings"
    ),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.gas_fee_settings,
    Component: loadModules("instance/pages/Settings/GasFeeSettings"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.reset_password,
    Component: loadModules("instance/pages/Settings/password-reset"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.profile,
    Component: loadModules("instance/pages/Settings/profile"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.instance.google_2fa,
    Component: loadModules("instance/pages/Settings/googleAuthSetup"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.exchanger.dashboard,
    Component: loadModules("exchanger/pages/Dashboard"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.exchanger.dispute_and_issues,
    Component: loadModules("exchanger/pages/Dashboard"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.exchanger.pool_summary,
    Component: loadModules("exchanger/pages/PoolSummary"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.exchanger.merchant_info,
    Component: loadModules("exchanger/pages/MerchantInfo"),
    access: "loggedin-user",
    isModal: false,
    isSpecialRoute: true,
  },
  {
    path: routes.protected.exchanger.p2p,
    Component: loadModules("exchanger/pages/P2P"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.exchanger.p2p_partners,
    Component: loadModules("exchanger/pages/P2P/P2PPartnerInformation"),
    access: "loggedin-user",
    isModal: false,
    isSpecialRoute: true,
  },
  {
    path: routes.protected.exchanger.p2p_kyc,
    Component: loadModules("exchanger/pages/P2P/P2PMerchantKYC"),
    access: "loggedin-user",
    isModal: false,
    isSpecialRoute: true,
  },
  {
    path: routes.protected.exchanger.users,
    Component: loadModules("exchanger/pages/Users"),
    access: "loggedin-user",
    isModal: false,
    isSpecialRoute: true,
  },
  {
    path: routes.protected.exchanger.transactions,
    Component: loadModules("exchanger/pages/Transactions"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.exchanger.frozen_wallet,
    Component: loadModules("exchanger/pages/Frozen-Wallet"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.exchanger.locations,
    Component: loadModules("exchanger/pages/Location"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.finswitch.dashboard,
    Component: loadModules("finswitch/pages/Dashboard"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.finswitch.pending_documents,
    Component: loadModules("finswitch/pages/Dashboard/PendingDocuments"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.finswitch.merchants,
    Component: loadModules("finswitch/pages/Merchant"),
    access: "loggedin-user",
    isModal: false,
  },

  {
    path: routes.protected.finswitch.merchant_info,
    Component: loadModules("finswitch/pages/Merchant/MerchantInfo"),
    access: "loggedin-user",
    isModal: false,
    isSpecialRoute: true,
  },
  {
    path: routes.protected.finswitch.transactions,
    Component: loadModules("finswitch/pages/Transactions"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.finswitch.transaction_details,
    Component: loadModules("finswitch/pages/Transactions/TransactionDetails"),
    access: "loggedin-user",
    isModal: false,
    isSpecialRoute: true,
  },
  {
    path: routes.protected.finswitch.applications,
    Component: loadModules("finswitch/pages/Applications"),
    access: "loggedin-user",
    isModal: false,
  },
  {
    path: routes.protected.finswitch.industries,
    Component: loadModules("finswitch/pages/Industry"),
    access: "loggedin-user",
    isModal: false,
  },
];

export default route;
