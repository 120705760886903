import axios from "axios";
import { useEffect, useId, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import routes from "../../../../common/routes";
import { createAdmin } from "../../../../store/admins/adminAsyncThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

function CreateAdmin() {
  const navigate = useNavigate();
  const { loading, error } = useAppSelector((state) => state.admins);
  const [inputError, setInputError] = useState(false);
  const dispatch = useAppDispatch();
  const id = useId();
  const mountOnce = useRef(false);
  const submitRef = useRef(false);
  const [countryList, setCountryList] = useState([]);
  const [state, setState] = useState({
    email: "",
    fullname: "",
    username: "",
    country: "",
    phone: "",
  });

  const changeHandler = (e: any) => {
    const { name, value } = e.target;
    const regex = new RegExp("^[0-9]*$");

    if (name !== "phone" || (name === "phone" && regex.test(value))) {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const blurHandler = (e: any) => {
    const { value } = e.target;
    const regex = new RegExp("^[0-9]{13}$");
    regex.test(value) ? setInputError(false) : setInputError(true);
  };

  useEffect(() => {
    if (mountOnce.current) return;
    axios
      .get("https://countriesnow.space/api/v0.1/countries/flag/unicode")
      .then((res) =>
        setCountryList(
          res.data.data.sort((a: any, b: any) => a.name.localeCompare(b.name))
        )
      )
      .catch((err) => console.log(err));
    mountOnce.current = true;
  }, []);

  useEffect(() => {
    if (loading === false && submitRef.current === true && !error) {
      navigate(routes.protected.instance.admin);
    }
  }, [loading, error, navigate]);

  const submitHandler = (e: any) => {
    e.preventDefault();

    dispatch(createAdmin({ ...state, phone: `+${state.phone}` }));
    submitRef.current = true;
  };

  return (
    <SideModal
      className="p-20"
      clickBackDrop={() => navigate(-1)}
      position={undefined}
    >
      <header className="pb-2 border-b border-gray-700">
        <h4 className="text-4xl font-medium text-base-300">Create Admin</h4>
      </header>
      <section className="pt-[18px] pb-12">
        <p className="text-base leading-5 text-gray-600">
          Input the details of the of the admin you are adding to the platform
        </p>
      </section>
      <form onSubmit={submitHandler}>
        <div className="mb-9">
          <label
            className="text-sm leading-none text-base-2"
            htmlFor={`${id}-name`}
          >
            Full name
          </label>
          <input
            className="mt-1 w-full border border-gray-800 rounded-xl py-2.5 px-2"
            required
            type="text"
            id={`${id}-name`}
            value={state.fullname}
            onChange={changeHandler}
            name="fullname"
            placeholder="full name"
          />
        </div>
        <div className="flex items-center gap-x-5">
          <div className="mb-9 flex-1">
            <label
              className="text-sm leading-none text-base-2"
              htmlFor={`${id}-username`}
            >
              Username
            </label>
            <input
              className="mt-1 w-full border border-gray-800 rounded-xl py-2.5 px-2"
              required
              type="text"
              id={`${id}-username`}
              value={state.username}
              onChange={changeHandler}
              name="username"
              placeholder="username"
            />
          </div>
          <div className="mb-9 flex-1">
            <label
              className="text-sm leading-none text-base-2"
              htmlFor={`${id}-country`}
            >
              Country
            </label>
            <select
              className="mt-1 w-full border border-gray-800 rounded-xl py-2.5 px-2"
              required
              id={`${id}-country`}
              value={state.country}
              onChange={changeHandler}
              name="country"
            >
              <option disabled hidden defaultChecked value=""></option>
              {countryList
                ? countryList.map((itm: any) => (
                    <option key={itm.name} value={itm.name}>
                      {itm.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="mb-9">
          <label
            className="text-sm leading-none text-base-2"
            htmlFor={`${id}-email`}
          >
            Email address
          </label>
          <input
            className="mt-1 w-full border border-gray-800 rounded-xl py-2.5 px-2"
            required
            type="email"
            id={`${id}-email`}
            value={state.email}
            onChange={changeHandler}
            name="email"
            placeholder="james@mail.com"
          />
        </div>
        <div className="mb-9">
          <label
            className="text-sm leading-none text-base-2"
            htmlFor={`${id}-phone`}
          >
            Phone number
          </label>
          <input
            className={`mt-1 w-full border ${
              !inputError ? "border-gray-800" : "border-red-600"
            } rounded-xl py-2.5 px-2`}
            type="text"
            id={`${id}-phone`}
            value={state.phone}
            onChange={changeHandler}
            onBlur={blurHandler}
            name="phone"
            placeholder="+2348087654321"
          />
        </div>
        <button
          // disabled={inputError}
          type="submit"
          className=" w-1/2 h-12 rounded-md text-white bg-blue-100 text-base"
        >
          Continue
        </button>
      </form>
    </SideModal>
  );
}

export default CreateAdmin;
