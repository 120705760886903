import axios, { AxiosError } from "axios";
import { BASE_URL as API_URL, REQUEST_TIMEOUT } from "../config/constant";
import {
  fetchUserToken,
  clearData,
  fetchRefreshToken,
  storeUserToken,
  storeRefreshToken,
} from "../storage";
import { APIResponseModel } from "types";

let isRefreshing = false;
let refreshSubscribers: any[] = [];

/** general headers **/
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

/** authorization header for logged in user **/
const setAuthorization = () => ({
  Authorization: `Bearer ${fetchUserToken()}`,
  "x-refresh-token": `${fetchRefreshToken()}`,
});

/** axios instance **/
const instance = axios.create({
  baseURL: API_URL,
  headers,
  withCredentials: true,
});

/** timeout configuration for axios instance **/
instance.defaults.timeout = REQUEST_TIMEOUT;

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error: any) => {
    const originalConfig = error.config;

    if (
      error.response &&
      error.response?.status === 401 &&
      !originalConfig._retry &&
      !["/admin/v1/auth/login"].includes(originalConfig.url!)
    ) {
      if (isRefreshing) {
        // If the token is being refreshed, wait for the new token and retry the original request
        return new Promise((resolve) => {
          refreshSubscribers.push(() => {
            resolve(
              makeAuthorizedRequestWithHeadersAndPayload(
                originalConfig.method,
                originalConfig.url,
                originalConfig.data
              )
            );
          });
        });
      }

      isRefreshing = true;

      try {
        const { data: res } = await axios.get(
          `${API_URL}admin/v1/auth/token/refresh`,
          {
            headers: {
              ...headers,
              ...setAuthorization(),
            },
            withCredentials: true,
            data: {},
          }
        );

        storeUserToken(res?.data?.access_token);
        storeRefreshToken(res?.data?.refresh_token);

        await makeAuthorizedRequestWithHeadersAndPayload(
          originalConfig.method,
          originalConfig.url,
          originalConfig.data
        );
      } catch (error) {
        clearData();
        window.location.reload();

        if (error instanceof AxiosError) {
          return Promise.reject({
            status: 401,
            message:
              error.response?.data?.message ||
              "Login session expired, please login again",
          });
        }

        return Promise.reject({
          status: 401,
          message: "Login session expired, please login again",
        });
      } finally {
        isRefreshing = false;
      }

      refreshSubscribers.forEach((subscriber) => {
        subscriber();
      });

      refreshSubscribers = [];

      return null;
    }

    if (error instanceof AxiosError && error.message === "Network Error") {
      return Promise.reject({
        status: 400,
        message: "Connnection Error",
      });
    }

    return Promise.reject(
      error
        ? error.response
          ? error.response.data?.message
            ? error.response.data
            : { message: "Something went wrong" }
          : error.response
        : { message: "Something went wrong" }
      // : Language.NetworkErrorMessage.errorMessage
    );
  }
);

/** make an axios get request **/
export const makeGetRequest = (path: string) => instance.get(path);

/** make an axios post request **/
export const makePostRequest = (path: string, payload: any) =>
  instance.post(path, payload);

/** make an axios request for a guest **/
export const makeUnauthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  data: any
) => {
  const response: APIResponseModel<any> = await instance.request({
    method,
    url,
    data,
    headers,
  });
  return response;
};

/** make an axios request for logged-in user **/
export const makeAuthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  data = {}
) => {
  const response: APIResponseModel<any> = await instance.request({
    method,
    url: url,
    data,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
  });
  return response;
};

/** make an axios request to submit a file for a logged in user **/
export const makeRequestWithFormData = async (
  method: string,
  url: string,
  data: any,
  authorized: any
) => {
  /** create new formdata object **/
  let formData = new FormData();

  let headers = {
    "Content-Type": "multipart/form-data",
  };

  /** loop through and append all data to formdata object **/
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      let fieldData = data[key];
      if (key === "texts" && data[key] !== "") {
        data[key].forEach((txtfield: any, idx: any) => {
          formData.append(`texts[${idx}][format]`, txtfield.format);
          formData.append(`texts[${idx}][text]`, txtfield.text);
        });
      }
      if (key === "media" && data[key].length !== 0) {
        data[key].forEach((image: any) => {
          formData.append(key, image.src);
        });
      }

      if (key !== "texts" && key !== "media") {
        formData.append(key, fieldData);
      }
    }
  }

  if (authorized) {
    headers = { ...headers, ...setAuthorization() };
  }

  const response = await instance.request({
    method,
    url: url,
    data: formData,
    headers,
  });

  return response;
};
