import { useEffect, useRef } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../../../../store/auth/authAsyncThunk";
import routes from "../../../routes";
import { fetchUserToken } from "../../../service/storage";
import Header from "../Header";
import SideNav from "../SideNav";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

type ProtectedContainerProps = {
  //  props to determine if the header title of the page is displayed or not
  isSpecialRoute?: boolean;
};

function Protected({ isSpecialRoute }: ProtectedContainerProps) {
  const user = useAppSelector((state) => state?.auth?.admin?._id);
  const account_verified = useAppSelector(
    (state) => state.auth?.admin?.account_verified
  );
  const dispatch = useAppDispatch();

  const mountOnce = useRef(false);
  useEffect(() => {
    if (mountOnce.current) return;

    if (user === "" && fetchUserToken()) {
      dispatch(getUser());
    }

    mountOnce.current = true;
  }, [dispatch, user]);

  if (!fetchUserToken() && account_verified === false) {
    return <Navigate replace to={routes.nonprotected.login} />;
  }

  return (
    <div className={`flex    bg-base-3 h-screen `}>
      <Header
        className="bg-white fixed left-0 top-0 w-full z-10"
        isSpecialRoute={isSpecialRoute}
      />
      <div className="flex w-full pt-[5.313rem] overflow-hidden h-full">
        <SideNav className="bg-white flex-[0.25] flex-shrink-0 overflow-y-auto" />
        <div
          className={
            "pt-[3.75rem] pr-14 pl-[4.25rem] pb-[4.5rem]  flex-[0.75] overflow-y-auto flex-shrink-0 "
          }
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}

Protected.defaultProps = {
  isSpecialRoute: false,
};

export default Protected;

// ${isSpecialRoute && "flex-grow"}
