import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "common/components/CustomToast";
import { finswitchServices as service } from "common/service/protected";
import { RequestBodyType } from "types";

export const getAllPendingMerchants = createAsyncThunk<any, RequestBodyType>(
  "pendingMerchants/getAllPendingMerchants",
  async (data, { rejectWithValue }) => {
    return service
      .getMerchants(data, false)
      .then((res) => res)
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);

export const approveMerchantAccount = createAsyncThunk<any, { id: string }>(
  "pendingMerchants/approveMerchantAccount",
  async (data, { rejectWithValue }) => {
    return service
      .approveMerchantAccount(data)
      .then((res) => {
        customToast(res.message);
        return data;
      })
      .catch((error) => {
        customToast(error.message, true);
        return rejectWithValue(error.message);
      });
  }
);
