import * as yup from "yup";

export const createSavingsSchema = yup.object().shape({
  name: yup.string().required(),
  interest_rate: yup.string().required("interest rate is required"),
  auto_save: yup
    .object({ text: yup.string(), value: yup.boolean() })
    .required(),
  withdrawal_intervals: yup
    .object({ text: yup.string(), value: yup.string() })
    .required("withdrawal intervals required"),
  interest_payout_intervals: yup
    .object({ text: yup.string(), value: yup.string() })
    .required(),
  savings_intervals: yup
    .object({ text: yup.string(), value: yup.string() })
    .required(),
});
