import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createAdmin, deactivateAdmin, getAllAdmins } from "./adminAsyncThunk";
import { AdminStateType } from "../../types";

const initialState: AdminStateType = {
  loading: false,
  data: [],
  error: "",
  pagination: {
    currentPage: 1,
    limit: 20,
    totalItems: 1,
  },
};

const adminSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<{ pageNumber: number }>) {
      state.pagination.currentPage = action.payload.pageNumber;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllAdmins.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.pagination.totalItems = action.payload?.results || 1;
      })
      .addCase(deactivateAdmin.fulfilled, (state, action) => {
        const temp = state.data.map((admin) => {
          if (admin._id === action.payload._id) {
            return action.payload;
          }
          return admin;
        });
        state.data = temp;
      })

      .addMatcher(
        isAnyOf(
          createAdmin.pending,
          getAllAdmins.pending,
          deactivateAdmin.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          createAdmin.rejected,
          createAdmin.fulfilled,
          getAllAdmins.rejected,
          getAllAdmins.fulfilled,
          deactivateAdmin.rejected,
          deactivateAdmin.fulfilled
        ),
        (state, action) => {
          state.loading = false;

          if (typeof action.payload === "string") {
            state.error = action.payload;
          }
        }
      );
  },
});

export const { setCurrentPage } = adminSlice.actions;
export default adminSlice.reducer;
