import { combineReducers } from "redux";
import auth from "./auth";
import admins from "./admins";
import stories from "./stories";
import users from "./users";
import dashboard from "./dashboard";
import transactions from "./transactions";
import savings from "./savings";
import authenticator from "./authenticator";

// finswitch
import merchants from "./merchants";
import industries from "./industries";
import applications from "./applications";
import pendingMerchants from "./pendingMerchants";
import finswitchTransactions from "./finswitchTransactions";
import merchantInfo from "./merchant-info";

// exchanger
import exchangers from "./exchangers";

export default combineReducers({
  auth,
  admins,
  stories,
  users,
  dashboard,
  transactions,
  savings,
  authenticator,
  merchants,
  industries,
  applications,
  pendingMerchants,
  finswitchTransactions,
  merchantInfo,
  exchangers,
});
