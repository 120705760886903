import { Route, Routes } from "react-router-dom";
import CreateLocation from "./CreateLocation";
import routes from "common/routes";

const modalRoutes = [
  {
    id: 1,
    component: CreateLocation,
    path: routes.protected.exchanger.create_location,
  },
];

export const ExchangerModals = () => {
  return (
    <Routes>
      {modalRoutes.map(({ component: Component, id, path }) => (
        <Route element={<Component />} key={id} path={path} />
      ))}
    </Routes>
  );
};
