import { Fragment } from "react";
import { RiArrowDownSFill } from "react-icons/ri";
import { BsCheck2 } from "react-icons/bs";
import { Transition, Listbox } from "@headlessui/react";
import { CurrentDashboardType } from "../../../../types";
import { dashBoards } from "common/utils/helpers/helper";

interface HeadlessDropDownProps {
  selected: CurrentDashboardType;
  handleChange(value: string): void;
}

export const HeadlessDropDown = ({
  selected,
  handleChange,
}: HeadlessDropDownProps) => {
  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="relative mt-1 w-[80%] mx-auto">
        <Listbox.Button className="relative w-full h-[3rem] rounded-[0.8rem]   bg-white py-2  text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm flex justify-between items-center border border-gray-800 px-6 cursor-pointer">
          <span className="block truncate">{dashBoards[selected].title}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <RiArrowDownSFill
              className="h-5 w-5 text-black"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {Object.values(dashBoards).map((dashboard) => (
              <Listbox.Option
                key={dashboard.value}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                    active ? "bg-blue-100 bg-opacity-20 " : "text-gray-900"
                  }`
                }
                value={dashboard.value}
              >
                {({}) => (
                  <>
                    <span
                      className={`block truncate ${
                        dashboard.value === selected
                          ? "font-medium"
                          : "font-normal"
                      }`}
                    >
                      {dashboard.title}
                    </span>
                    {dashboard.value === selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                        <BsCheck2 className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
