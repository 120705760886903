import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { LocalDataType } from "types";
import { MerchantType } from "types/merchant-types";
import { getAllMerchants, updateMerchant } from "./merchantAsyncThunk";

const initialState: LocalDataType<MerchantType[]> = {
  data: [],
  error: "",
  loading: false,
  pagination: {
    currentPage: 1,
    limit: 20,
    totalItems: 1,
  },
};

const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<{ page: number }>) {
      state.pagination.currentPage = action.payload.page;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getAllMerchants.pending, (state) => {
      state.loading = true;
    });

    builders.addCase(getAllMerchants.fulfilled, (state, action) => {
      state.data = action.payload?.data || [];
      state.pagination.totalItems = action.payload?.results || 1;
      state.error = "";
    });

    builders.addMatcher(
      isAnyOf(
        getAllMerchants.fulfilled,
        getAllMerchants.rejected,
        updateMerchant.rejected,
        updateMerchant.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const { setCurrentPage } = merchantSlice.actions;

export default merchantSlice.reducer;
