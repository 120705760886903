import React from "react";
import { TiDelete } from "react-icons/ti";
import Dropzone from "../../../../common/components/CustomDragandDrop";

function MediaUpload({ onDrop, images, onSubmit, onRemoveMedia }: any) {
  return (
    <>
      <div className="py-7 px-12 shadow-storyImageUpload rounded mt-14">
        <Dropzone onDrop={onDrop} accept={"image/*"} open={() => {}} />
        <div className="pt-5">
          <h5 className="text-gray-250 text-sm leading-none font-bold">
            Uploading - {`${images.length}/${images.length}`} files
          </h5>

          <div className="pt-3 flex flex-col gap-y-2.5">
            {images.map((image: any) => (
              <div
                key={image.id}
                className=" p-2.5 border border-gray-550 rounded flex items-center justify-between relative before:w-1/2 before:h-1 before:bottom-0 before:left-0 before:absolute before:bg-purple-450"
              >
                <span className="text-xs leading-none">{image.name}</span>
                <button
                  onClick={() => onRemoveMedia(image.id)}
                  className="text-gray-650 text-base hover:text-red-300"
                >
                  <TiDelete size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <button
        disabled={images.length === 0}
        onClick={onSubmit}
        type="button"
        className="px-18 py-3 rounded-md text-white bg-blue-100 text-base mt-11 disabled:bg-slate-400"
      >
        Publish Story
      </button>
    </>
  );
}

export default MediaUpload;
