import cuid from "cuid";
import React, { useId, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";

function TextUpload({ setState, state, onSubmit }: any) {
  const id = useId();
  const initialstate = { format: "text", text: "" };
  const [input, setInput] = useState(initialstate);
  const inputChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInput((prev) => ({ ...prev, text: value }));
  };
  const formatChangeHandler = (e: any) => {
    const { name } = e.target;
    setInput((prev) => ({ ...prev, format: name }));
  };
  const addTextToThread = () => {
    setState((prev: any) => ({
      ...prev,
      texts: [...prev.texts, { ...input, id: cuid() }],
    }));
    setInput(initialstate);
  };
  const removeFromThread = (id: any) => {
    const temp = state.texts.filter((txt: any) => txt.id !== id);
    setState((prev: any) => ({ ...prev, texts: [...temp] }));
  };

  return (
    <>
      <div className="mt-16 flex flex-col">
        <div className="flex items-center justify-between">
          <label
            className="text-sm leading-4.5 text-base-50"
            htmlFor={`${id}-message`}
          >
            Text Upload
          </label>
          <div className="flex items-center gap-x-2">
            <p className="text-sm">Text Format - </p>
            <button
              name="text"
              onClick={formatChangeHandler}
              className="p-1 font-bold"
            >
              B
            </button>
            <button
              name="text"
              onClick={formatChangeHandler}
              className="p-1 italic"
            >
              I
            </button>
            <button
              name="text"
              onClick={formatChangeHandler}
              className="p-1 underline"
            >
              U
            </button>
            <button
              name="text"
              onClick={formatChangeHandler}
              className="p-1 sup"
            >
              T
            </button>
          </div>
        </div>
        <textarea
          className="mt-1 w-full border border-gray-800 rounded py-2.5 px-2"
          placeholder="Input text  to upload"
          cols={8}
          rows={7}
          id={`${id}-message`}
          name="text_upload"
          value={input.text}
          onChange={inputChangeHandler}
        />
        <button onClick={addTextToThread} className=" self-end mt-5">
          Add Text
        </button>
      </div>
      {state.texts.length !== 0 ? (
        <div className="flex flex-col gap-y-3">
          <h5>Text story thread</h5>
          <div className="flex flex-col gap-y-2.5">
            {state.texts.map((txt: any) => (
              <div
                key={txt.id}
                className=" p-2.5 border border-green-300 rounded flex items-center justify-between relative "
              >
                <span className="text-xs leading-none">{txt.text}</span>
                <button
                  onClick={() => removeFromThread(txt.id)}
                  className="text-red-300 p-1 bg-red-200 rounded text-base hover:animate-bounce"
                >
                  <RiDeleteBin5Line size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <button
        disabled={state.texts.length === 0}
        type="button"
        onClick={onSubmit}
        className="px-18 py-3 rounded-md text-white bg-blue-100 text-base mt-11 disabled:bg-slate-400 "
      >
        Publish Story
      </button>
    </>
  );
}

export default TextUpload;
