import { CurrentDashboardType } from "types";
import { STORAGE_KEYS } from "../config/constant";
// import {
// 	LoggedInUserDetails,
// 	LoggedOutUserDetails,
// } from "../../../modules/auth/types/session-model";

const getItem = (key: string) => {
  return localStorage.getItem(key) || null;
};
const removeItem = (key: string) => {
  localStorage.removeItem(key);
  return null;
};

const setItem = (key: string, value: any) => {
  return localStorage.setItem(key, value);
};

export const clearData = () => localStorage.clear();

export const storeUserDetails = (userDetails: any) => {
  setItem(STORAGE_KEYS.USER_DETAILS_STORAGE_KEY, JSON.stringify(userDetails));
};

export const clearUserDetails = () => {
  removeItem(STORAGE_KEYS.USER_DETAILS_STORAGE_KEY);
  removeItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
  removeItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY);
};

export const fetchUserDetails = () => {
  let details = getItem(STORAGE_KEYS.USER_DETAILS_STORAGE_KEY);
  if (details) {
    return JSON.parse(details);
  }
  return { username: "", loggedIn: false };
};

export const storeUserToken = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY, token);
};
export const storeRefreshToken = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY, token);
};

export const fetchUserToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
};
export const fetchRefreshToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY);
};

export const storeUserDashboardPreference = (
  preference: CurrentDashboardType
) => {
  return setItem(STORAGE_KEYS.CLIENT_DASHBOARD_PREFERENCE_KEY, preference);
};
export const getUserDashboardPreference = () => {
  let preference = getItem(STORAGE_KEYS.CLIENT_DASHBOARD_PREFERENCE_KEY);

  const preferences = ["instance", "exchanger", "finswitch"];

  if (!preference || !preferences.includes(preference)) {
    storeUserDashboardPreference("instance");
    preference = "instance";
  }

  return preference as CurrentDashboardType;
};

export const fetchForbidden = () => {
  let details = getItem(STORAGE_KEYS.CLIENT_FORBBIDEN_ERROR_STORAGE_KEY);
  if (details) {
    details = JSON?.parse(details);
    return details;
  }
};
