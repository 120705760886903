import { useLocation, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import BackDrop from "common/components/CustomModal/AlertModal/BackDrop";
import { DashboardInput } from "common/components/DashboardComponents/Inputs/dashbaord-input";
import CustomButtom from "common/components/CustomButton";
import routes from "common/routes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback, useEffect, useRef } from "react";
import {
  resendVerifyCode,
  updateUserSettings,
  verifyUserWithOtp,
} from "store/auth/authAsyncThunk";

const PasswordAction = () => {
  const { register, handleSubmit, setValue } = useForm<{
    password: string;
    code: string;
  }>();
  const {
    state: { state, settings_name },
  } = useLocation();

  const navigate = useNavigate();
  const { loading, error } = useAppSelector((state) => state.auth);
  const updateSettingsSubmitRef = useRef(false);

  const dispatch = useAppDispatch();

  const goBack = useCallback(() => {
    navigate(routes.protected.instance.settings, {
      state: null,
      replace: true,
    });
  }, [navigate]);

  const handlePreferenceUpdate: SubmitHandler<{
    password: string;
    code: string;
  }> = ({ code, password }) => {
    dispatch(
      verifyUserWithOtp({
        data: { code },
        navigateSuccess() {
          dispatch(updateUserSettings({ [settings_name]: !state, password }));
          updateSettingsSubmitRef.current = true;
        },
        navigateError() {},
      })
    );
  };

  useEffect(() => {
    if (
      loading === false &&
      updateSettingsSubmitRef.current === true &&
      !error
    ) {
      goBack();
    }
  }, [goBack, loading, error]);

  const handleSendVerificatinCode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(resendVerifyCode());
  };

  return (
    <BackDrop handleClose={goBack} className="w-[40rem] min-h-[20rem]">
      <div className="h-full w-full">
        <div className="mb-6">
          <h4 className="font-semibold  text-xl">
            Authentication Required To Update Preference
          </h4>
        </div>
        <form className="flex flex-col  gap-y-6">
          <div className="flex gap-y-2 flex-shrink-0 flex-col relative">
            <label className="text-[#090914] font-medium inline-block">
              Email Verification Code
            </label>
            <input
              type="text"
              autoComplete="off"
              pattern="[0-9]+"
              {...register("code", {
                required: true,
                onChange(e) {
                  const { value } = e.target;
                  const sanitizedValue = value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");

                  setValue("code", sanitizedValue);
                },
              })}
              className="bg-[#F8FAFC] outline-none focus:ring-2 focus:ring-[background: #293B96;
] focus:ring-offset-0 hover:ring-opacity-30 transition duration-150 border border-[#CBD5E1] rounded-xl w-full h-[3rem] flex-shrink-0 px-3"
              maxLength={6}
            />
            <button
              className="absolute right-4 top-[55%] text-green-400 font-bold cursor-pointer"
              onClick={handleSendVerificatinCode}
            >
              Send Code
            </button>
          </div>
          <DashboardInput
            labelText="Enter Password"
            type="password"
            register={register("password", {
              required: true,
            })}
            autoComplete="current-password"
          />
          <div className="max-w-[10rem]">
            <CustomButtom action={handleSubmit(handlePreferenceUpdate)}>
              Submit
            </CustomButtom>
          </div>
        </form>
      </div>
    </BackDrop>
  );
};

export default PasswordAction;
