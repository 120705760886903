import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../common/components/CustomToast";
import auth from "../../common/service/auth";
import { UserSettingsOption } from "types";

export const loginAdmin = createAsyncThunk<any, any>(
  "auth/loginAdmin",
  async (data, { rejectWithValue }) => {
    return auth
      .loginAdmin(data)
      .then((res) => {
        customToast(res.message || "Login Successful");
        return res.data;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const twoFactorAuth = createAsyncThunk<any, any>(
  "auth/twoFactor",
  async (inData: any) => {
    return auth
      .twoFactorAuth(inData.data)
      .then((res: any) => {
        customToast(res.message);
        inData.navigateSuccess();
      })
      .catch((err) => {
        customToast(err.message, true);
        inData.navigateError();
        // return rejectWithValue(err);
      });
  }
);
export const getUser = createAsyncThunk("auth/getUser", async () => {
  return auth
    .getAdmin()
    .then((res) => res.data)
    .catch((err) => customToast(err.message, true));
});
export const forgotPassword = createAsyncThunk<any, any>(
  "auth/forgotPassword",
  async (data) => {
    return auth
      .forgotPassword(data)
      .then((res: any) => customToast(res.message))
      .catch((err) => customToast(err.message, true));
  }
);
export const resetPassword = createAsyncThunk<any, any>(
  "auth/resetPassword",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .resetPassword(data)
      .then((res: any) => {
        customToast(res.message);
        fulfillWithValue(res.message);
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const resendVerifyCode = createAsyncThunk(
  "auth/resendVerifyCode",
  async () => {
    return auth
      .resendCode()
      .then((res: any) => customToast(res.message))
      .catch((err) => customToast(err.message, true));
  }
);
export const verifyUserWithOtp = createAsyncThunk(
  "auth/verifyUserWithOtp",
  async (inData: any) => {
    return auth
      .verifyUser(inData.data)
      .then((res: any) => {
        inData.navigateSuccess();
        customToast(res.message);
      })
      .catch((err) => {
        inData.navigateError();
        customToast(err.message, true);
      });
  }
);

export const updateUserSettings = createAsyncThunk<any, UserSettingsOption>(
  "auth/updateUserSettings",
  async (settings, { rejectWithValue }) => {
    return auth
      .updateUserSettings(settings)
      .then((res) => {
        customToast(res.message);
        return res.data;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err.message);
      });
  }
);
