import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { LocalDataType } from "types";
import { FinswitchTransactionType } from "types/finswitch-transaction-types";
import { getFinswitchTransactions } from "./finswitchTransactionsAsyncThunk";

export const initialState: LocalDataType<FinswitchTransactionType[]> = {
  error: "",
  data: [],
  loading: false,
  pagination: {
    currentPage: 1,
    limit: 20,
    totalItems: 1,
  },
};

export const finswitchTransactionsSlice = createSlice({
  name: "finswitchTransactions",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<{ page: number }>) {
      state.pagination.currentPage = action.payload.page;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getFinswitchTransactions.pending, (state) => {
      state.loading = true;
    });
    builders.addCase(getFinswitchTransactions.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.pagination.totalItems = action.payload.results || 1;
    });

    builders.addMatcher(
      isAnyOf(
        getFinswitchTransactions.rejected,
        getFinswitchTransactions.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const { setCurrentPage } = finswitchTransactionsSlice.actions;
export default finswitchTransactionsSlice.reducer;
