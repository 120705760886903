export const ENDPOINTS = {
  auth: {
    signin: {
      method: "POST",
      url: "/admin/v1/auth/login",
    },
    two_fa: {
      method: "POST",
      url: "/admin/v1/auth/verify/2fa",
    },
    signup: {
      method: "POST",
      url: "/admin/v1/auth/register",
    },
    update_password: {
      method: "PATCH",
      url: "/admin/v1/auth/update-password",
    },
    forgot_password: {
      method: "POST",
      url: "/admin/v1/auth/forgot-password",
    },
    reset_password: {
      method: "PATCH",
      url: "/admin/v1/auth/reset-password",
    },
    get_user: {
      method: "GET",
      url: "/admin/v1/auth/me",
    },
    logout: {
      method: "GET",
      url: "/admin/v1/auth/logout",
    },
    update_email: {
      method: "PATCH",
      url: "/admin/v1/auth/update-email",
    },
    send_verification_code: {
      method: "GET",
      url: "/admin/v1/auth/send-verification",
    },
    verify_user: {
      method: "POST",
      url: "/admin/v1/auth/verify",
    },
    create_super_admin: {
      method: "PATCH",
      url: "/admin/v1/auth/super/create-superadmin",
    },
    create_admin: {
      method: "PATCH",
      url: "/admin/v1/auth/super/create-admin",
    },
    deactivate_admin: {
      method: "PATCH",
      url: "/admin/v1/auth/super/deactivate-admin",
    },
    refresh_access_token: {
      method: "GET",
      url: "/admin/v1/auth/token/refresh",
    },
    update_user_settings: {
      method: "PATCH",
      url: "/admin/v1/auth/settings",
    },
  },
  stories: {
    create_a_story: {
      method: "POST",
      url: "/admin/v1/story",
    },
    retrive_all_stories: {
      method: "POST",
      url: "/admin/v1/all/story",
    },
    retrive_story_views: {
      method: "POST",
      url: "/admin/v1/all/story-views",
    },
    delete_a_story: {
      method: "DELETE",
      url: (id: string) => `/admin/v1/single/story/${id}`,
    },
    get_a_story: {
      method: "GET",
      url: (id: string) => `/admin/v1/single/story/${id}`,
    },
  },
  admins: {
    retrive_all_admins: {
      method: "POST",
      url: "/admin/v1/all/admin",
    },
    retrive_single_admin: {
      method: "GET",
      url: (id: string) => `/admin/v1/single/admin/${id}`,
    },
    deactivate_admin: {
      method: "PATCH",
      url: "/admin/v1/auth/super/deactivate-admin",
    },
    retrive_all_users: {
      method: "POST",
      url: "/admin/v1/all/user",
    },
    retrive_single_user: {
      method: "GET",
      url: (id: string) => `/admin/v1/single/user/${id}`,
    },
    toggle_user_permission: {
      method: "PATCH",
      url: "/admin/v1/permissions/user",
    },
    toggle_user_status: {
      method: "PATCH",
      url: "/admin/v1/toggle-active-status/user",
    },
  },
  transactions: {
    get_all_transactions: {
      method: "POST",
      url: "/admin/v1/all/transaction",
    },
    get_all_assets: {
      method: "POST",
      url: "/admin/v1/all/asset",
    },
    get_all_bank_formats: {
      method: "POST",
      url: "/admin/v1/all/account-format",
    },
    create_asset: {
      method: "POST",
      url: "/admin/v1/asset",
    },
    delete_asset: {
      method: "PATCH",
      url: (id: string) => `/admin/v1/single/asset/${id}`,
    },
    view_asset: {
      method: "GET",
      url: (id: string) => `/admin/v1/single/asset/${id}`,
    },
  },
  savings: {
    get_all_savings_plan: {
      method: "POST",
      url: "/admin/v1/all/savings-plan",
    },
    create_savings_plan: {
      method: "POST",
      url: "/admin/v1/savings/plan",
    },
  },
  authenticator: {
    get_started: {
      method: "GET",
      url: "/admin/v1/auth/2fa/start",
    },
    verify_code: {
      method: "POST",
      url: "/admin/v1/auth/2fa/enable",
    },
  },

  // finswitch
  merchants: {
    compliance: {
      list: {
        method: "GET",
        url: (id: string) => `/admin/v1/finswich/compliance?company_id=${id}`,
      },
      list_shareholders: {
        method: "GET",
        url: (id: string) =>
          `/admin/v1/finswich/compliance/shareholders?company_id=${id}`,
      },
    },

    list_merchants: {
      method: "GET",
      url: (status?: boolean, name = "", page = 1, page_size = 5) =>
        `/admin/v1/finswich/merchants?page=${page}&page_size=${page_size}&admin_approved=${status}&start_date=&end_date&name=${name}`,
    },

    retrieve_merchant: {
      method: "GET",
      url: (id: string) => `/admin/v1/finswich/merchants/${id}`,
    },
    list_apps: {
      method: "GET",
      url: (
        company_id = "",
        page_size = 20,
        page = 1,
        name = "",
        is_active = ""
      ) =>
        `/admin/v1/finswich/apps?page=${page}&page_size=${page_size}&company_id=${company_id}&name=${name}&is_active=${is_active}`,
    },
    delete_app: {
      method: "DELETE",
      url: (id: string) => `/admin/v1/finswich/apps/${id}`,
    },
    update_app_status: {
      method: "PATCH",
      url: (id: string) => `/admin/v1/finswich/apps/${id}/status`,
    },
    retrieve_app: {
      method: "GET",
      url: (id: string) => `/admin/v1/finswich/apps/${id}`,
    },
    approve_merchant: {
      method: "POST",
      url: (id: string) => `/admin/v1/finswich/merchants/${id}/approve`,
    },
    update_merchant_status: {
      method: "PATCH",
      url: (id: string) => `/admin/v1/finswich/merchants/${id}/status`,
    },
    update_compliance_doc: {
      method: "PATCH",
      url: (id: string) => `/admin/v1/finswich/compliance/${id}`,
    },

    list_policy: {
      method: "GET",
      url: (appId: string) => `/admin/v1/finswich/apps/${appId}/policy`,
    },
    retrieve_policy: {
      method: "GET",
      url: (appId: string, policyId: string) =>
        `/admin/v1/finswich/apps/${appId}/policy/${policyId}`,
    },

    update_policy: {
      method: "PATCH",
      url: (appId: string, policyId: string) =>
        `/admin/v1/finswich/apps/${appId}/policy/${policyId}`,
    },
  },

  industries: {
    create: {
      method: "POST",
      url: "/admin/v1/finswich/industry",
    },
    get: {
      method: "GET",
      url: (page = 1, page_size = 20, name = "") =>
        `/admin/v1/finswich/industry?page=${page}&page_size=${page_size}&name=${name}`,
    },
    delete: {
      method: "DELETE",
      url: (id: string) => `/admin/v1/finswich/industry/${id}`,
    },
  },

  finswitch_transactions: {
    get_all: {
      method: "GET",
      url: (
        page: number = 1,
        page_limit: number = 20,
        start_date = "",
        end_date = "",
        mode = "",
        type = "",
        status = "",
        external_api_initiated = "",
        name = "",
        app_consumer_id = "",
        merchant_app_id = "",
        txn_reference = ""
      ) =>
        `/admin/v1/finswich/transactions?page=${page}&page_size=${page_limit}&start_date=${start_date}&end_date=${end_date}&mode=${mode}&type=${type}&status=${status}&external_api_initiated=${external_api_initiated}&name=${name}&app_consumer_id=${app_consumer_id}&merchant_app_id=${merchant_app_id}&txn_reference=${txn_reference}`,
    },
    get_single: {
      method: "GET",
      url: (id: string) => `/admin/v1/finswich/transactions/${id}`,
    },
  },

  exchanger: {
    get_exchangers: {
      method: "POST",
      url: "/admin/v1/intrapay/exchangers",
    },
    search_exchangers: {
      method: "POST",
      url: "/admin/v1/intrapay/exchangers/search",
    },
    block_exchanger: {
      method: "POST",
      url: "/admin/v1/intrapay/settings/exchanger/block",
    },
    unblock_exchanger: {
      method: "POST",
      url: "/admin/v1/intrapay/settings/exchanger/unblock",
    },
    block_funding: {
      method: "POST",
      url: "/admin/v1/intrapay/settings/funding/block",
    },
    unblock_funding: {
      method: "POST",
      url: "/admin/v1/intrapay/settings/funding/unblock",
    },
    block_withdrawal: {
      method: "POST",
      url: "/admin/v1/intrapay/settings/withdrawal/block",
    },
    unblock_withdrawal: {
      method: "POST",
      url: "/admin/v1/intrapay/settings/withdrawal/unblock",
    },
    get_funding_transactions: {
      method: "POST",
      url: "/admin/v1/intrapay/transaction/funding",
    },
    get_withdrawal_transactions: {
      method: "POST",
      url: "/admin/v1/intrapay/transaction/withdrawal",
    },
    get_pool_summary: {
      method: "POST",
      url: "/admin/v1/intrapay/pool/summary",
    },
    get_issues: {
      method: "POST",
      url: "/admin/v1/intrapay/pool/issues",
    },
    get_completed_requests: {
      method: "POST",
      url: "/admin/v1/intrapay/pool/request/completed",
    },
    get_processing_requests: {
      method: "POST",
      url: "/admin/v1/intrapay/pool/request/processing",
    },
    get_pending_requests: {
      method: "POST",
      url: "/admin/v1/intrapay/pool/request/pending",
    },
    get_tray_requests: {
      method: "POST",
      url: "/admin/v1/intrapay/pool/request/tray",
    },
  },
};
