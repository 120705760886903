import * as yup from "yup";

export const createAssetSchema = yup.object().shape({
  name: yup.string().required(),
  crypto_symbol: yup.string().required("crypto symbol is required"),
  category: yup.object({ text: yup.string(), value: yup.string() }).required(),
  country: yup.object({ text: yup.string(), value: yup.string() }).required(),
  country_currency: yup
    .object({ text: yup.string(), value: yup.string() })
    .required("country currency is required"),
  decimal_places: yup.string().optional(),
});
